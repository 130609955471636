/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import iconPin from '../../../assets/images/icon_pin.svg';
import badgeStar from '../../../assets/images/badge_star.svg';
import defaultAvatar from '../../../assets/images/avatar_default.svg';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import defaultCover from '../../../assets/images/athlete_header_bg.png';
import { MatchParams, Media } from '../../../constants/misc';
import { userURL } from '../../../services/users';
import ProfileError from '../../elements/ProfileError';
import { AppRoute } from '../../../constants/routes';
import { Badge, User } from '../../../types/user';
import { enumToString } from '../../../utils/format';
import Map from '../../elements/Map';
import IconPhone from '../../assets/IconPhone';
import { SponsorContext, withSponsorContext } from '../../controllers/sponsor/SponsorContext';
import Badges from '../../elements/Badges';
import { SponsorAthletesSupport } from '../../../constants/sponsor';
import { TableActionType } from '../../../utils/table';
import SponsorshipManagerTable from '../../elements/SponsorshipManagerTable';
import UserWallet from '../../elements/UserWallet';
import ScrollWrapper from '../../elements/ScrollWrapper';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, UserContext, SponsorContext {}

type Props = OwnProps;

interface OwnState {
    showGallery: boolean;
    coverPhoto: Media | null;
    avatar: Media | null;
    biography: string;
    name: string;
    lastName: string;
    address: string;
    mediaList: any;
    objectives: string;
    phrase: string;
    phone: string;
    linkURL: string;
    error: boolean;
    userData: User | null;
    modality: string | null;
    sponsoredAthletes: Badge[];
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsSponsorshipManager: Record<string, number>;
    isScrolling: boolean;
}

type State = OwnState;

const initialState: State = {
    showGallery: false,
    coverPhoto: null,
    avatar: null,
    biography: '',
    name: '',
    lastName: '',
    modality: null,
    address: '',
    mediaList: [],
    objectives: '',
    phrase: '',
    phone: '',
    linkURL: '',
    error: false,
    userData: null,
    sponsoredAthletes: [],
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsSponsorshipManager: {
        _limit: 10,
        _page: 0,
    },
    isScrolling: false,
};

class ManagerScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        const { match } = this.props;

        window.scrollTo(0, 0);

        if (match && match.params && match.params.id) {
            this.prepare(match.params.id);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const {
            match, user, pendingAthleteSupporters,
        } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters } = prevProps;
        
        if (match && match.params && typeof match.params.id === 'string') {
            if (prevProps.match.params.id !== match.params.id) {
                this.prepare(match.params.id);
            }
        }

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAllSupportData();
        }
    }

    onEditClick = () => {
        const { history } = this.props;
        
        history.push(AppRoute.ManagerProfile);
    }

    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    onPageChange = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSponsorshipManager } = this.state;

        this.setState(
            {
                paramsSponsorshipManager: { ...paramsSponsorshipManager, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSponsorshipManager } = this.state;

        this.setState(
            {
                paramsSponsorshipManager: { ...paramsSponsorshipManager, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    fetchAthletesSupport = () => {
        const { paramsSponsorshipManager } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsSponsorshipManager })
            .then(({ list, total }) => {
                this.setState({
                    athletesSupportList: list,
                    totalAthletesSupport: total,
                });
            });
    }

    fetchAllSupportData = () => {
        this.fetchAthletesSupport();
    };

    prepare = async (id: string): Promise<void> => {
        const {
            user, getSponsoredAthletesBadges,
        } = this.props;

        const promises: [Promise<AxiosResponse>, Promise<Badge[]>] = [axios.get(userURL(id)), getSponsoredAthletesBadges(id)];

        let res = [];
        try {
            res = await Promise.all(promises);
        } catch (error) {
            this.setState({
                error: true,
            });
            return;
        }

        const [userResponse, sponsoredAthletes] = res;

        const { data } = userResponse;

        if (data) {
            const {
                coverPhoto, avatar, biography, name, lastName, address, phrase, phone, modality,
            } = data;

            this.setState({
                coverPhoto,
                avatar,
                biography,
                name,
                lastName,
                address,
                modality,
                phone,
                phrase,
                userData: data,
                linkURL: `${AppRoute.Manager}/${id}`,
                sponsoredAthletes,
            });
        }

        if (user?.id === userResponse.data.id) this.fetchAthletesSupport();
    }

    renderTable = () => {
        const { athletesSupportList, totalAthletesSupport, paramsSponsorshipManager } = this.state;
        
        return (
            <SponsorshipManagerTable
                totalAthletesSupport={totalAthletesSupport}
                athletesSupportList={athletesSupportList}
                onPageChange={this.onPageChange}
                onRowsPerPageChange={this.onRowsPerPageChange}
                params={paramsSponsorshipManager}
                fetchData={this.fetchAthletesSupport}
                actionButtons={[TableActionType.CANCEL]}
            />
        );
    }

    render(): React.ReactNode {
        const {
            avatar,
            coverPhoto,
            showGallery,
            name,
            lastName,
            modality,
            phrase,
            biography,
            linkURL,
            error,
            userData,
            address,
            phone,
            sponsoredAthletes,
            athletesSupportList,
        } = this.state;
        const { t, user } = this.props;

        const currentCover = coverPhoto?.main || defaultCover;
        const currentAvatar = avatar?.main || null;

        const itsMe = user?.id === userData?.id;

        return (
            <div className="profile-screen" data-testid="manager-screen">
                <ProfileError show={error} close={(): void => this.setState({ error: false })} />
                <div className="profile-screen__body">
                    <div className="profile-screen__body__left">
                        <div className="profile-screen__body__left__header">
                            <div className="cover">
                                <div className="cover__image" style={{ backgroundImage: `url(${currentCover})` }} />
                            </div>
                            <ScrollWrapper>
                                {(isScrolling) => (
                                    <div className={`buttons ${isScrolling ? ' buttons--scroll' : ''}`} data-testid={isScrolling ? ' buttons-scroll' : 'buttons'}>
                                        { itsMe && (
                                            <React.Fragment>
                                                <button type="button" className="btn btn--primary-inverse" onClick={this.onEditClick}>
                                                    {t('nav.editProfile')}
                                                </button>
                                                <UserWallet userPageOwner={userData} />
                                            </React.Fragment>
                                        )}
                                        <ButtonContact userPageOwner={userData} />
                                        <ButtonShare linkURL={linkURL} />
                                    </div>
                                )}
                            </ScrollWrapper>
                            <Avatar image={currentAvatar || defaultAvatar} badge={badgeStar} />
                            <button
                                type="button"
                                className="btn btn--primary-dark gallery-btn"
                                onClick={this.onToggleGallery}
                                data-testid="gallery-toggle"
                            >
                                {t('athlete.galery')}
                            </button>
                        </div>
                        {!showGallery ? (
                            <div className="profile-screen__body__left__content">
                                <div className="profile-top">
                                    <div className="athlete-info">
                                        <h1>
                                            {name} {lastName}
                                        </h1>
                                        <h2>{modality ? enumToString(modality) : ''}</h2>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('athlete.about')}</h3>
                                                <h4>{phrase}</h4>
                                                <p>{biography || t('general.noInfo')}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="content-box">
                                                <h3 className="title">{t('profile.myLocation')}</h3>
                                                <div className="content-box__item content-box__item--centered">
                                                    <img className="icn icn--green" src={iconPin} alt="" />
                                                    <p>{address}</p>
                                                </div>
                                                <div className="content-box__item content-box__item--centered">
                                                    <IconPhone />
                                                    <p>{phone}</p>
                                                </div>
                                                <Map location={address} />
                                            </div>
                                            <div className="content-box">
                                                <div className="content-box__with-button">
                                                    <h3 className="title">{t('athlete.supportedAthletes')}</h3>
                                                </div>
                                                <div className="content-box__item content-box__item--sponsored">
                                                    {sponsoredAthletes.map((sponsoredAthlete) => (
                                                        <Badges
                                                            userId={sponsoredAthlete.id}
                                                            userRole={sponsoredAthlete.role}
                                                            badgeId={sponsoredAthlete.badgeUrl.id}
                                                            badgeUrl={sponsoredAthlete.badgeUrl.main}
                                                            refreshScreen={this.prepare}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {athletesSupportList.length > 0 && (
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="content-box">
                                                    <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                                                    {this.renderTable()}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div data-testid="mobile-gallery" className="profile-screen__body__left__gallery">
                                <DrawerGallery galleryUser={userData} canEdit />
                            </div>
                        )}
                    </div>
                    <div className="profile-screen__body__right">
                        <DrawerGallery galleryUser={userData} canEdit />
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withSponsorContext(withUserContext(withTranslationContext(ManagerScreen))));
