/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum AppRoute {
    About = '/about',
    Admin = '/admin/users',
    AdminInvite = '/admin/users/add',
    Athlete = '/athlete',
    AthleteGallery = '/athlete/:id/gallery',
    AthleteProfile = '/profile/athlete',
    AthleteProfileSupport = '/profile/athlete/support/:id',
    Credit = '/market/credits',
    Fan = '/fan',
    FanGallery = '/fan/:id/gallery',
    FanProfile = '/profile/fan',
    Index = '/',
    Invite = '/register/:token',
    Manager = '/manager',
    ManagerGallery = '/manager/:id/gallery',
    ManagerProfile = '/profile/manager',
    Market = '/market',
    NotFound = '/not_found',
    PaymentFail = '/payment/fail',
    PaymentSuccess = '/payment/success',
    Product = '/sponsor/product/:id',
    ResetPassword = '/reset-password/:token',
    Sponsor = '/sponsor',
    SponsorGallery = '/sponsor/:id/gallery',
    SponsorProfile = '/profile/sponsor',
    Sportest = '/sportest',
    SportNetwork = '/rede-sportrack',
    SportNetworkSearch = '/rede-sportrack/:q',
    ValidateAcount = '/validate-account/:token',
}
