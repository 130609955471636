/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

export enum SportTypes {
    AMATEUR_FIGHTS = 'AMATEUR_FIGHTS',
    ARCHERY = 'ARCHERY',
    ATHLETICS = 'ATHLETICS',
    BADMINTON = 'BADMINTON',
    BASKETBALL = 'BASKETBALL',
    BOCCIA = 'BOCCIA',
    BOXING = 'BOXING',
    CANOEING = 'CANOEING',
    CRICKET = 'CRICKET',
    CROSSFIT = 'CROSSFIT',
    CYCLING = 'CYCLING',
    EQUESTRIANISM_DRESSAGE = 'EQUESTRIANISM_DRESSAGE',
    EQUESTRIANISM_JUMPING = 'EQUESTRIANISM_JUMPING',
    FENCING = 'FENCING',
    FIELD_HOCKEY = 'FIELD_HOCKEY',
    FITNESS = 'FITNESS',
    FUTSAL = 'FUTSAL',
    GOLF = 'GOLF',
    GYMNASTICS = 'GYMNASTICS',
    HANDBALL = 'HANDBALL',
    JUDO = 'JUDO',
    KARATE = 'KARATE',
    MODERN_PENTATHLON = 'MODERN_PENTATHLON',
    MOTORBIKES = 'MOTORBIKES',
    PADEL_TENNIS = 'PADEL_TENNIS',
    PARA_CANOEING = 'PARA_CANOEING',
    PARADRESSAGE = 'PARADRESSAGE',
    PARALYMPIC_ATHLETICS = 'PARALYMPIC_ATHLETICS',
    PARALYMPIC_BADMINTON = 'PARALYMPIC_BADMINTON',
    PARALYMPIC_JUDO = 'PARALYMPIC_JUDO',
    PARALYMPIC_SHOOTING = 'PARALYMPIC_SHOOTING',
    PARALYMPIC_SWIMMING = 'PARALYMPIC_SWIMMING',
    ROLLER_HOCKEY = 'ROLLER_HOCKEY',
    ROWING = 'ROWING',
    RUGBY = 'RUGBY',
    RUNNING = 'RUNNING',
    SAILING = 'SAILING',
    SHOOTING_WITH_HUNTING_GUNS = 'SHOOTING_WITH_HUNTING_GUNS',
    SHOOTING = 'SHOOTING',
    SOCCER = 'SOCCER',
    SWIMMING = 'SWIMMING',
    SYNCHRONIZED_SWIMMING = 'SYNCHRONIZED_SWIMMING',
    TABLE_TENNIS = 'TABLE_TENNIS',
    TAEKOWONDO = 'TAEKOWONDO',
    TENNIS = 'TENNIS',
    TRIATHLON = 'TRIATHLON',
    VOLLEYBALL = 'VOLLEYBALL',
    WATER_JUMPS = 'WATER_JUMPS',
    WATER_POLO = 'WATER_POLO',
    WEIGHTLIFTING = 'WEIGHTLIFTING',
    NA = 'NA',
}
