/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ReactSVG } from 'react-svg';
import { isEqual } from 'lodash';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { UserContext, withUserContext } from '../../controllers/user/UserContext';
import DrawerGallery from '../../elements/DrawerGallery';
import ButtonContact from '../../elements/ButtonContact';
import ButtonShare from '../../elements/ButtonShare';
import Avatar from '../../elements/Avatar';
import iconPin from '../../../assets/images/icon_pin.svg';
import FormField from '../../elements/FormField';
import {
    SponsorProfileFormFields, UserRoles, Sponsor,
} from '../../../types/user';
import { DropdownOption, KeyedObject, MatchParams } from '../../../constants/misc';
import { AppRoute } from '../../../constants/routes';
import { displayError, displaySuccess } from '../../../utils/notifications';
import { ErrorCode } from '../../../constants/errors';
import { fileURL } from '../../../services/files';
import loader from '../../../assets/images/loader.svg';
import { IconWWW } from '../../assets/IconWWW';
import { IconGlobe } from '../../assets/IconGlobe';
import { IconHome } from '../../assets/IconHome';
import { IconBank } from '../../assets/IconBank';
import { SportestContext, withSportestContext } from '../../controllers/sportest/SportestContext';
import CoverImage from '../../elements/CoverImage';
import { withSponsorContext, SponsorContext } from '../../controllers/sponsor/SponsorContext';
import SponsorProductList from '../../elements/SponsorProductList';
import ProductModal from '../../elements/ProductModal';
import UserWallet from '../../elements/UserWallet';
import SelectCountry from '../../elements/SelectCountry';
import SponsorshipManagerTable from '../../elements/SponsorshipManagerTable';
import { SponsorAthletesSupport } from '../../../constants/sponsor';
import { SocketEventType } from '../../../types/websockets';
import { WS } from '../../../utils/websocket';
import { TableActionType } from '../../../utils/table';
import RouteLeavingGuard from '../RouteLeavingGuard';
import ScrollWrapper from '../../elements/ScrollWrapper';

interface OwnProps
    extends RouteComponentProps<MatchParams>,
        AuthenticationContext,
        TranslationContext,
        UserContext,
        SponsorContext,
        SportestContext {}

type Props = OwnProps;
 
interface OwnState {
    changesMade: boolean;
    cover: any;
    avatar: any;
    showGallery: boolean;
    userData: Sponsor | null;
    fields: SponsorProfileFormFields;
    errors: KeyedObject | null;
    isFetching: boolean;
    showProductModal: boolean;
    athletesSupportList: SponsorAthletesSupport[];
    totalAthletesSupport: number;
    paramsSponsorshipSponsor: Record<string, number>;
    isSocketConnecting: boolean;
    isScrolling: boolean;
}

type State = OwnState;

const initialState: State = {
    changesMade: false,
    cover: null,
    avatar: null,
    showGallery: false,
    userData: null,
    fields: {
        name: '',
        phone: '',
        countryCode: null,
        postalCode: '',
        fiscalCode: '',
        address: '',
        phrase: '',
        aboutUs: '',
        biography: '',
        avatarId: null,
        coverId: null,
    },
    errors: null,
    isFetching: false,
    showProductModal: false,
    athletesSupportList: [],
    totalAthletesSupport: 0,
    paramsSponsorshipSponsor: {
        _limit: 10,
        _page: 0,
    },
    isSocketConnecting: false,
    isScrolling: false,
};

/**
 * shows the profile screen
 */
class ProfileScreen extends Component<Props, State> {
    state = initialState;

    private readonly avatarRef = React.createRef<HTMLInputElement>();

    private readonly coverRef = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        const { submitPendingSportests, isAuthenticated } = this.props;
        const { isSocketConnecting } = this.state;
        submitPendingSportests();
        this.prepare();

        if (isAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { user, pendingAthleteSupporters, isAuthenticated } = this.props;
        const { user: prevUser, pendingAthleteSupporters: prevPendingAthleteSupporters, isAuthenticated: prevIsAuthenticated } = prevProps;
        const { isSocketConnecting, fields } = this.state;
        const { fields: prevFields } = prevState;

        if (user?.availableCredits !== prevUser?.availableCredits || pendingAthleteSupporters !== prevPendingAthleteSupporters) {
            this.fetchAthletesSupport();
        }

        if (isAuthenticated && !prevIsAuthenticated && !isSocketConnecting) {
            WS.addHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
        }

        if (isEqual(prevFields, initialState.fields)
            || isEqual(fields, initialState.fields)
            || isEqual(prevFields, fields)
        ) return;

        this.setState({ changesMade: true });
    }

    componentWillUnmount(): void {
        WS.removeHandler(SocketEventType.SUPPORTERS_UPDATE, this.handleSupportUpdate);
    }

    /**
     * handlers
     */

    onToggleGallery = (): void => {
        const { showGallery } = this.state;

        this.setState({
            showGallery: !showGallery,
        });
    };

    onInputChange = (e: any) => {
        const { value, name } = e.currentTarget;
        this.setState((prevState: State) => ({
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    onDropdownChange = (option: DropdownOption | null, name: string): void => {
        const { fields } = this.state;

        this.setState({
            changesMade: true,
            fields: {
                ...fields,
                [name]: option,
            },
        });
    };

    onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();

        const {
            sponsorValidateProfileUpdate, sponsorProfileUpdate, user, match: { params: { id } },
        } = this.props;
        const { fields } = this.state;

        const errors = sponsorValidateProfileUpdate(fields);

        this.setState(
            {
                errors,
            },
            () => {
                if (!errors) {
                    sponsorProfileUpdate(fields, this.onSuccess, this.onFailure, user?.role === UserRoles.Admin ? id : undefined);
                }
            },
        );
    };

    onSuccess = () => {
        const { t, user, history } = this.props;
        this.setState(
            {
                changesMade: false,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                this.prepare();
                if (user?.role === UserRoles.Admin) {
                    displaySuccess({
                        message: t('admin.users.updated'),
                    });
                    return;
                }
                displaySuccess({
                    message: t('profile.updated'),
                });

                history.push(`${AppRoute.Sponsor}/${user?.id}`);
            },
        );
    };

    onFailure = (errors: KeyedObject): void => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onCoverFileDialog = () => {
        if (this.coverRef.current) {
            this.coverRef.current.click();
        }
    };

    onCoverSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { coverUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    cover: reader.result,
                    isFetching: true,
                },
                () => {
                    coverUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    coverId: response.data.id,
                                },
                                isFetching: false,
                            }));
                        },
                        () => {
                            this.setState({
                                isFetching: false,
                            });
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onAvatarFileDialog = () => {
        if (this.avatarRef.current) {
            this.avatarRef.current.click();
        }
    };

    onAvatarSelected = (files: FileList | null) => {
        const { t } = this.props;
        if (!files) return;
        const { avatarUpdate } = this.props;

        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
            this.setState(
                {
                    avatar: reader.result,
                },
                () => {
                    avatarUpdate(
                        file,
                        (response: any) => {
                            this.setState((prevState: State) => ({
                                fields: {
                                    ...prevState.fields,
                                    avatarId: response.data.id,
                                },
                                isFetching: false,
                            }));
                        },
                        () => {
                            displayError({
                                message: t('profile.uploadError'),
                            });
                        },
                    );
                },
            );
        };

        reader.readAsDataURL(file);
    };

    onPageChange = (evt: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        const { paramsSponsorshipSponsor } = this.state;

        this.setState(
            {
                paramsSponsorshipSponsor: { ...paramsSponsorshipSponsor, _page: page },
            },
            this.fetchAthletesSupport,
        );
    };

    onRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { paramsSponsorshipSponsor } = this.state;

        this.setState(
            {
                paramsSponsorshipSponsor: { ...paramsSponsorshipSponsor, _limit: Number(evt.target.value) },
            },
            this.fetchAthletesSupport,
        );
    };

    /**
     * requests & actions
     */

    prepare = async (): Promise<void> => {
        const {
            user, history, match, getSponsor,
        } = this.props;

        const userId = match?.params?.id || user?.id || '';

        const [sponsor] = await Promise.all([getSponsor(userId)]);

        if (!sponsor) {
            history.push(AppRoute.Index);
            return;
        }

        this.setState({
            userData: sponsor,
            fields: {
                name: sponsor.name || initialState.fields.name,
                countryCode: sponsor.countryCode || initialState.fields.countryCode,
                postalCode: sponsor.postalCode || initialState.fields.postalCode,
                fiscalCode: sponsor.fiscalCode || initialState.fields.fiscalCode,
                address: sponsor.address || initialState.fields.address,
                phone: sponsor.phone || initialState.fields.phone,
                phrase: sponsor.phrase || initialState.fields.phrase,
                aboutUs: sponsor.aboutUs || initialState.fields.aboutUs,
                biography: sponsor.biography || initialState.fields.biography,
                avatarId: sponsor.avatar?.id || initialState.fields.avatarId,
                coverId: sponsor.coverPhoto?.id || initialState.fields.coverId,
            },
        });

        if (user?.id === sponsor.id) this.fetchAthletesSupport();
    };

    fetchAthletesSupport = () => {
        const { paramsSponsorshipSponsor } = this.state;
        const { getAthletesSupport } = this.props;

        getAthletesSupport({ ...paramsSponsorshipSponsor })
            .then(({ list, total }) => {
                this.setState({
                    athletesSupportList: list,
                    totalAthletesSupport: total,
                });
            });
    }

    removeCoverImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                cover: undefined,
                userData: {
                    ...userData,
                    coverPhoto: null,
                },
                fields: {
                    ...fields,
                    coverId: 0,
                },
            });
        }
    }

    removeUserImage = () => {
        const { fields, userData } = this.state;
        if (userData) {
            this.setState({
                avatar: undefined,
                userData: {
                    ...userData,
                    avatar: null,
                },
                fields: {
                    ...fields,
                    avatarId: 0,
                },
            });
        }
    }

    handleSupportUpdate = ({ payload: athleteSupportEvent }: {payload: Partial<SponsorAthletesSupport>}) => {
        const { athletesSupportList } = this.state;
        
        const supportElement = athletesSupportList.find((el) => el.id === athleteSupportEvent.id);
        
        if (!supportElement) {
            return;
        }

        const auxList = athletesSupportList.map((el) => {
            if (el.id === supportElement.id) return { ...el, ...athleteSupportEvent };

            return el;
        });

        this.setState({
            athletesSupportList: auxList,
        });
    }

    /**
     * renders
     */

    renderInputsFiles = () => {
        return (
            <React.Fragment>
                <input
                    id="cover-input"
                    data-testid="cover-input"
                    type="file"
                    ref={this.coverRef}
                    onChange={(e) => this.onCoverSelected(e.target.files)}
                />
                <input
                    id="avatar-input"
                    data-testid="avatar-input"
                    type="file"
                    ref={this.avatarRef}
                    onChange={(e) => this.onAvatarSelected(e.target.files)}
                />
            </React.Fragment>
        );
    };

    renderHeaderContent(): React.ReactNode {
        const { t } = this.props;
        const {
            cover, avatar, userData,
        } = this.state;
        
        const currentCover = userData?.coverPhoto ? fileURL(userData?.coverPhoto.id) : null;
        const currentAvatar = userData?.avatar ? fileURL(userData?.avatar.id) : null;
        const linkURL = `${AppRoute.Sponsor}/${userData?.id}`;

        return (
            <React.Fragment>
                <CoverImage
                    image={cover || currentCover}
                    onEdit={this.onCoverFileDialog}
                    onDelete={this.removeCoverImage}
                    tooltip={t('profile.coverTooltip')}
                />
                <ScrollWrapper>
                    {(isScrolling) => (
                        <div className={`buttons ${isScrolling ? ' buttons--scroll' : ''}`} data-testid={isScrolling ? ' buttons-scroll' : 'buttons'}>
                            <button type="button" className="btn btn--primary-inverse" onClick={this.onSubmit}>
                                {t('profile.save')}
                            </button>
                            <UserWallet userPageOwner={userData} />
                            <ButtonContact userPageOwner={userData} />
                            <ButtonShare linkURL={linkURL} />
                        </div>
                    )}
                </ScrollWrapper>
                <Avatar
                    image={avatar || currentAvatar}
                    editable
                    onEdit={this.onAvatarFileDialog}
                    onDelete={this.removeUserImage}
                />
                <button
                    type="button"
                    className="btn btn--primary-dark gallery-btn"
                    onClick={this.onToggleGallery}
                >
                    {t('sponsorProfile.galery')}
                </button>
            </React.Fragment>
        );
    }

    renderSponsorInfo(): React.ReactNode {
        const { t } = this.props;
        const { fields, errors, isFetching } = this.state;

        return (
            <div className="row athlete-info">
                <div className="col-xl-3 col-sm-6 col-xs-12">
                    <FormField
                        label={t('profile.name')}
                        type="text"
                        name="name"
                        value={fields.name}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    renderSponsorStats() {
        const { isFetching, t } = this.props;
        const { fields, errors } = this.state;

        const colClass = 'col-xl-3 col-lg-4 col-sm-6 col-xs-12 with-icon';

        return (
            <div className="row athlete-stats">
                <div className={colClass}>
                    <div className="icn icn--phone">
                        <IconWWW />
                    </div>
                    <FormField
                        label={t('sponsorProfile.website')}
                        type="text"
                        name="phone"
                        value={fields.phone}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn icn--phone">
                        <IconBank />
                    </div>
                    <FormField
                        label={t('sponsorProfile.fiscalCode')}
                        type="text"
                        name="fiscalCode"
                        value={fields.fiscalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <img src={iconPin} alt="" />
                    </div>
                    <FormField
                        label={t('sponsorProfile.address')}
                        type="text"
                        name="address"
                        value={fields.address}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconHome />
                    </div>
                    <FormField
                        label={t('sponsorProfile.postalCode')}
                        type="text"
                        name="postalCode"
                        value={fields.postalCode}
                        onChange={this.onInputChange}
                        placeholder=""
                        disabled={isFetching}
                        errors={errors}
                    />
                </div>
                <div className={colClass}>
                    <div className="icn">
                        <IconGlobe />
                    </div>
                    <SelectCountry
                        name="countryCode"
                        label={t('sponsorProfile.country')}
                        value={fields.countryCode}
                        onChange={this.onDropdownChange}
                        errors={errors}
                    />
                </div>
            </div>
        );
    }

    renderProfileInfo = (): React.ReactNode => {
        const { t } = this.props;
        const {
            errors, fields, userData, isFetching,
        } = this.state;

        if (!userData) return null;

        return (
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="content-box">
                        <h3 className="title">{t('sponsorProfile.about')}</h3>
                        <FormField
                            label={t('sponsorProfile.tag')}
                            type="text"
                            name="phrase"
                            value={fields.phrase}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                        <FormField
                            label={t('sponsorProfile.description')}
                            type="text"
                            name="aboutUs"
                            value={fields.aboutUs}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                        <FormField
                            label={t('sponsorProfile.biography')}
                            type="text"
                            name="biography"
                            value={fields.biography}
                            onChange={this.onInputChange}
                            placeholder=""
                            disabled={isFetching}
                            multiline
                            errors={errors}
                        />
                    </div>
                </div>
                <SponsorProductList
                    showPlaceholders
                    showEdit
                    sponsor={userData}
                />
            </div>
        );
    };

    render(): React.ReactNode {
        const { t, history } = this.props;
        const {
            userData, showGallery, isFetching,
            showProductModal, paramsSponsorshipSponsor,
            totalAthletesSupport, athletesSupportList, changesMade,
        } = this.state;

        if (!userData) return null;

        return (
            <>
            
                <RouteLeavingGuard
                    when={changesMade}
                    navigate={(pathName: string) => {
                        history.push(pathName);
                    }}
                    shouldBlockNavigation={() => true}
                    title={t('profile.noChangesPersistedConfirmation')}
                    confirmText={t('general.confirm')}
                    cancelText={t('general.cancel')}
                />
                <div className="profile-screen" data-testid="profile-screen">
                    <div className="profile-screen__body">
                        {showProductModal && (
                            <ProductModal
                                onSave={(): void => {
                                    this.setState({ showProductModal: false });
                                }}
                                product={null}
                                onClose={(): void => this.setState({ showProductModal: false })}
                            />
                        )}
                        <div className="profile-screen__body__left with-overlay">
                            <div className="profile-screen__body__left__header">
                                {this.renderInputsFiles()}
                                {isFetching && (
                                    <div className="fetching">
                                        <ReactSVG wrapper="span" src={loader} />
                                    </div>
                                )}
                                {this.renderHeaderContent()}
                            </div>
                            {!showGallery ? (
                                <div className="profile-screen__body__left__content">
                                    <div className="profile-top">
                                        <div className="profile-top__left">
                                            {this.renderSponsorInfo()}
                                            <div className="hidden-xs stats-container">
                                                {this.renderSponsorStats()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        <div className="row hidden-sm hidden-md hidden-lg">
                                            <div className="col-sm-12">{this.renderSponsorStats()}</div>
                                        </div>
                                        {this.renderProfileInfo()}
                                        {athletesSupportList.length > 0 && (
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="content-box">
                                                        <h3 className="title">{t('profile.sponsorshipManager.mySupportedAthletes')}</h3>
                                                        <SponsorshipManagerTable
                                                            totalAthletesSupport={totalAthletesSupport}
                                                            athletesSupportList={athletesSupportList}
                                                            onPageChange={this.onPageChange}
                                                            onRowsPerPageChange={this.onRowsPerPageChange}
                                                            params={paramsSponsorshipSponsor}
                                                            fetchData={this.fetchAthletesSupport}
                                                            actionButtons={[TableActionType.CANCEL]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="profile-screen__body__left__gallery">
                                    <DrawerGallery galleryUser={userData} canEdit />
                                </div>
                            )}
                        </div>
                        <div className="profile-screen__body__right">
                            <DrawerGallery galleryUser={userData} canEdit />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withAuthenticationContext(
    withSponsorContext(
        withUserContext(
            withTranslationContext(
                withSportestContext(ProfileScreen),
            ),
        ),
    ),
);
