/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_white.svg';
import runningImage from '../../assets/images/running.png';
import modalSlant from '../../assets/images/modal_slant.svg';
import FormField from '../elements/FormField';
import { RegistrationInviteFormFields, RegistrationInvitePostData } from '../../constants/authentication';
import { KeyedObject } from '../../constants/misc';
import { displayError, displaySuccess } from '../../utils/notifications';
import { termsURL } from '../../services/terms';
import { Terms } from '../../constants/terms';
import { ErrorCode } from '../../constants/errors';
import IconTimes from '../assets/IconTimes';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import { TabType } from '../../utils/misc';
import { UserRoles, UserTypes } from '../../types/user';

/**
 * @typedef {Object} MatchParams
 * @property {string} token
 */
interface MatchParams {
    token: string;
}

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {boolean} isFetching
 * @property {Function} close
 * @property {Function} submit
 * @property {Function} validate
 */
interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {
    isFetching: boolean;
    close: Function;
    submit: Function;
    validate: Function;
    openLogin(): void;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} agree
 * @property {RegistrationInviteFormFields} fields
 * @property {KeyedObject} [errors]
 * @property {Terms} [terms]
 * @property {boolean} showTermsAndConditions
 * @property {string} userType
 */
interface OwnState {
    agree: boolean;
    fields: RegistrationInviteFormFields;
    errors: KeyedObject | null;
    terms: Terms | null;
    showTermsAndConditions: boolean;
}

const initialState: OwnState = {
    agree: false,
    fields: {
        name: '',
        lastName: '',
        password: '',
        cpassword: '',
    },
    errors: null,
    terms: null,
    showTermsAndConditions: false,
};

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * shows the login screen
 * @extends {Component<Props, State>}
 */
class RegistrationScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.prepare();
    }

    /**
     * prepares data for the view
     */
    prepare = async () => {
        try {
            const { data } = await axios.get(termsURL());
            this.setState({
                ...this.state,
                terms: data,
            });
        } catch {}
    };

    /**
     * handles input change
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    /**
     * checkbox change handler
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onCheckChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.checked,
        });
    };

    /**
     * handles not implemented link click
     * @param {React.MouseEvent} e
     */
    onInvalidClick = (e: React.MouseEvent) => {
        const { t } = this.props;
        e.preventDefault();
        displayError({ message: t('general.unavailable') });
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const {
            submit,
            validate,
            t,
            match: {
                params: { token },
            },
        } = this.props;
        const { fields, agree, terms } = this.state;

        if (agree) {
            const errors = validate(fields, token && token.includes(UserTypes.Sponsor));

            this.setState(
                {
                    ...this.state,
                    errors,
                },
                () => {
                    if (!errors) {
                        const formData: RegistrationInvitePostData = {
                            name: fields.name,
                            lastName: fields.lastName,
                            password: fields.password,
                            token,
                            acceptedTermsOfUse: terms ? terms.version : '',
                        };
                        submit(formData, this.onSuccess, this.onFailure);
                    }
                },
            );
        } else {
            displayError({
                message: t('registration.accept'),
            });
        }
    };

    /**
     * handles open terms and conditions
     */
    onTermsAndConditionsClick = () => {
        this.setState({
            showTermsAndConditions: true,
        });
    };

    /**
     * handles login success
     */
    onSuccess = (role: UserRoles) => {
        const {
            close, openLogin, t,
        } = this.props;
        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
                agree: false,
            },
            () => {
                close();
                openLogin();
                displaySuccess({ message: t('registration.successMessage') });
            },
        );
    };

    /**
     * handles login failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }

        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    render() {
        const {
            close,
            isFetching,
            t,
            match: {
                params: { token },
            },
        } = this.props;
        const {
            agree, fields, errors, showTermsAndConditions,
        } = this.state;

        return (
            <Fragment>
                <div className="modal registration-screen" data-testid="registration-screen">
                    <div className="modal__backdrop" />
                    <div className="modal__content">
                        <button
                            type="button"
                            className="modal__content__close"
                            data-testid="close-modal"
                            onClick={() => close()}
                        >
                            <IconTimes fill="#FFF" />
                        </button>
                        <div className="modal__content__top">
                            <div className="modal__content__top__header">
                                <img src={logo} className="logo" alt="" />
                                <img src={runningImage} className="running" alt="" />
                                <img src={modalSlant} className="slant" alt="" />
                            </div>
                            <div className="modal__content__top__subheader">
                                <h1>{t('registration.title')}</h1>
                                <p>{t('registration.description')}</p>
                            </div>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.name')}
                                        type="text"
                                        name="name"
                                        value={fields.name}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    {(!token || !token.includes(UserTypes.Sponsor)) && (
                                        <FormField
                                            label={t('registration.lastName')}
                                            type="text"
                                            name="lastName"
                                            value={fields.lastName}
                                            onChange={this.onInputChange}
                                            placeholder=""
                                            disabled={isFetching}
                                            errors={errors}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.password')}
                                        type="password"
                                        name="password"
                                        value={fields.password}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FormField
                                        label={t('registration.repeat')}
                                        type="password"
                                        name="cpassword"
                                        value={fields.cpassword}
                                        onChange={this.onInputChange}
                                        placeholder=""
                                        disabled={isFetching}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="register-button-container">
                                    <button
                                        type="submit"
                                        className="btn btn--primary-inverse btn--block"
                                        disabled={isFetching}
                                    >
                                        {t('registration.send')}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="modal__content__bottom">
                            <label className="control control--checkbox">
                                <input
                                    name="agree"
                                    type="checkbox"
                                    checked={agree}
                                    onChange={this.onCheckChange}
                                    disabled={isFetching}
                                />
                                <div className="indicator" />
                                <p>{t('registration.read')}&nbsp;
                                    <a href="#a" onClick={this.onTermsAndConditionsClick}>
                                        {t('registration.terms')}
                                    </a>
                                </p>
                            </label>
                            <p>{t('registration.link')}</p>
                            <p>
                                <a href="#a" onClick={this.onInvalidClick}>
                                    {t('registration.resend')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                {showTermsAndConditions && (
                    <TermsAndConditionsScreen
                        close={() => this.setState({ showTermsAndConditions: false })}
                        tab={TabType.TERMS_CONDITIONS}
                        inRegister
                    />
                )}
            </Fragment>
        );
    }
}

export default withTranslationContext(withRouter(RegistrationScreen));
