/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormField from '../elements/FormField';
import IconTimes from '../assets/IconTimes';
import logo from '../../assets/images/logo_white.svg';
import { ResendActivationFormFields } from '../../constants/authentication';
import { KeyedObject } from '../../constants/misc';
import { validateForm, ValidationType } from '../../utils/validations';
import { displayError, displaySuccess } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { AppRoute } from '../../constants/routes';

/**
 * @typedef {Object} MatchParams
 * @property {string} token
 */
interface MatchParams {
    token: string;
}

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext {}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {ResendActivationFormFields} fields
 * @property {boolean} [validated]
 * @property {KeyedObject} [errors]
 */
interface OwnState {
    validated: boolean | null;
    fields: ResendActivationFormFields;
    errors: KeyedObject | null;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    validated: false,
    fields: {
        email: '',
    },
    errors: null,
};

/**
 * shows the validate account screen
 * @extends {Component<Props, State>}
 */
class ValidateAccountScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const {
            history,
            accountValidate,
            match: {
                params: { token },
            },
            t,
            openLogin,
        } = this.props;

        accountValidate(
            { token },
            () => {
                history.push(AppRoute.Index);
                openLogin();
                displaySuccess({
                    message: t('activate.success'),
                });
            },
            () => {
                this.setState({
                    validated: false,
                });
                this.onValidationFailure();
            },
        );
    }

    /**
     * handles input change
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    /**
     * handles form submit
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { resendActivation } = this.props;
        const { fields } = this.state;

        const errors = validateForm(this.state.fields, {
            email: {
                validations: [ValidationType.NotBlank],
            },
        });

        this.setState(
            {
                ...this.state,
                errors,
            },
            () => {
                if (!errors) {
                    resendActivation(fields, this.onSuccess, this.onFailure);
                }
            },
        );
    };

    /**
     * handles validate account success
     */
    onSuccess = () => {
        const { t } = this.props;
        this.setState(
            {
                ...this.state,
                fields: initialState.fields,
                errors: null,
            },
            () => {
                displaySuccess({
                    message: t('activate.message'),
                });
            },
        );
    };

    /**
     * handles validate account failure
     * @param {KeyedObject} errors
     */
    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;
        if (errors.fields) {
            this.setState({
                ...this.state,
                errors: errors.fields,
            });
        }
        if (errors.errors) {
            errors.errors.forEach((error: any) => {
                displayError({
                    message: t(`errors.${ErrorCode[error.errorCode]}`),
                });
            });
        }
    };

    onValidationFailure = () => {
        const { openLogin, t } = this.props;
        openLogin();
        displayError({
            message: t('errors.validationToken'),
        });
    }

    /**
     * handles close click
     * @param {React.MouseEvent} e
     */
    onCloseClick = (e: React.MouseEvent) => {
        const { history } = this.props;

        history.push(AppRoute.Index);
    };

    /**
     * returns the appropriate error message
     * @returns {string}
     */
    renderMessage = (): string => {
        const { t } = this.props;
        return t('activate.error');
    };

    render() {
        const { isFetching, t } = this.props;
        const { validated, fields, errors } = this.state;

        if (validated !== false || isFetching) return null;

        return (
            <div className="validate-account-screen" data-testid="validate-account-screen">
                <button type="button" onClick={this.onCloseClick} data-testid="activate-close-button">
                    <IconTimes fill="#FFF" />
                </button>
                <div>
                    <img src={logo} alt="" />
                    <h1>{t('activate.title')}</h1>
                    <p>{this.renderMessage()}</p>
                    <form onSubmit={this.onSubmit}>
                        <FormField
                            type="text"
                            name="email"
                            value={fields.email}
                            placeholder={t('activate.email')}
                            onChange={this.onInputChange}
                            disabled={isFetching}
                            errors={errors}
                        />
                        <button type="submit" className="btn btn--primary-inverse" disabled={isFetching}>
                            {t('activate.submit')}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(ValidateAccountScreen));
