/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import { AppRoute } from '../../constants/routes';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withAuthenticationContext, AuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import add from '../../assets/images/add.svg';
import {
    ProfileStatus, User, UserFilter, UserRoles,
} from '../../types/user';
import { fileURL } from '../../services/files';
import IconEdit from '../assets/IconEdit';
import { displayError } from '../../utils/notifications';
import IconCheckbox from '../assets/IconCheckbox';
import InviteScreen from './InviteScreen';
import IconCheckedBox from '../assets/IconCheckedBox';
import { hasAnyErrors } from '../../utils/validations';
import { ErrorCode } from '../../constants/errors';
import iconDelete from '../../assets/images/delete.svg';
import { ConfirmationModal } from '../elements/ConfirmationModal';
import { UserContext, withUserContext } from '../controllers/user/UserContext';
import { IconCreditCard } from '../assets/IconCreditCard';
import CreditEditModal from '../elements/CreditEditModal';
import { ListParams, Order } from '../../constants/misc';
import { SearchInput } from '../elements/SearchInput';
import Filter from '../elements/Filter';
import { SportTypes } from '../../types/sport';
import { FilterOptions } from '../../types/filter';

interface OwnProps extends TranslationContext, RouteComponentProps, AuthenticationContext, UserContext {}

type Props = OwnProps;

interface OwnState {
    searchValue: string;
    selectedUser?: User;
    users: User[];
    totalUsers: number;
    params: ListParams;
    showInvite: boolean;
    showConfirmationModal: boolean;
    showCrediManagementModal: boolean;
    userTypes: UserFilter[];
    sports: SportTypes[];
    profileStatus?: ProfileStatus | null;
}

type State = OwnState;

const initialState: State = {
    searchValue: '',
    selectedUser: undefined,
    users: [],
    totalUsers: 0,
    params: {
        _limit: 10,
        _page: 0,
        _order: Order.ASCENDING,
        _sort: 'name',
    },
    showInvite: false,
    showConfirmationModal: false,
    showCrediManagementModal: false,
    userTypes: [UserFilter.ALL],
    sports: [],
    profileStatus: null,
};

class UsersScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount(): void {
        const { user, history } = this.props;
        if (user?.role !== UserRoles.Admin) {
            history.push(AppRoute.Index);
        }
        this.fetchUsers();
    }

    onCreditCardClick = (selectedUser: User): void => {
        this.setState({ selectedUser, showCrediManagementModal: true });
    };

    onDeleteUserIconClick = (selectedUser: User): void => {
        this.setState({ selectedUser, showConfirmationModal: true });
    }

    onSortChange = (field: string): void => {
        const { params } = this.state;
        const { _sort, _order } = params;

        this.setState({
            params: {
                ...params,
                _sort: field,
                _order: (field === _sort && _order !== Order.DESCENDING) ? Order.DESCENDING : Order.ASCENDING,
            },
        }, this.fetchUsers);
    }

    onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number): void => {
        if (event !== null) event.preventDefault();

        this.setState((prevState: State) => ({
            params: { ...prevState.params, _page: page },
        }), this.fetchUsers);
    };

    onRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { target: { value: limit } } = evt;

        this.setState((prevState: State) => ({
            params: { ...prevState.params, _limit: Number(limit) },
        }), this.fetchUsers);
    };

    onSearchSubmit = (searchValue: string): void => {
        const { params } = this.state;

        this.setState({
            searchValue,
            params: {
                ...params,
                _page: 0,
            },
        }, this.fetchUsers);
    };

    deleteUser = async (): Promise<void> => {
        const { selectedUser } = this.state;

        if (!selectedUser) return;

        const { deleteUser } = this.props;

        await deleteUser(selectedUser);

        this.setState({
            selectedUser: undefined,
            showConfirmationModal: false,
        }, this.fetchUsers);
    }

    fetchUsers = async (): Promise<void> => {
        const { getUsersAdmin, searchUsersAdmin } = this.props;
        const {
            params, searchValue, userTypes, sports, profileStatus,
        } = this.state;

        let typeParams;
        let profileStatusParams;

        const isSearch = searchValue.length > 2;

        const searchParams = { q: searchValue || undefined };

        if (!userTypes.includes(UserFilter.ALL)) {
            typeParams = { type: userTypes || undefined };
        }

        const sportsParams = { sport: sports || undefined };
        
        if (profileStatus !== null) {
            if (profileStatus === ProfileStatus.HIDDEN) {
                profileStatusParams = { hiddenProfile: true };
            } else {
                profileStatusParams = { hiddenProfile: false };
            }
        }
        
        const { data: users, total: totalUsers } = isSearch
            ? await searchUsersAdmin({
                ...params, ...searchParams, ...typeParams, ...sportsParams, ...profileStatusParams,
            })
            : await getUsersAdmin({
                ...params, ...typeParams, ...sportsParams, ...profileStatusParams,
            });

        this.setState({ users, totalUsers });
    };

    handleUserClickNavigation = (user: User, isViewing: boolean): void => {
        const { history } = this.props;
        const { role, id } = user;

        switch (role) {
            case UserRoles.Athlete:
                history.push(`${isViewing ? AppRoute.Athlete : AppRoute.AthleteProfile}/${id}`);
                break;
            case UserRoles.Manager:
                history.push(`${isViewing ? AppRoute.Manager : AppRoute.ManagerProfile}/${id}`);
                break;
            case UserRoles.Sponsor:
                history.push(`${isViewing ? AppRoute.Sponsor : AppRoute.SponsorProfile}/${id}`);
                break;
            case UserRoles.Fan:
                history.push(`${isViewing ? AppRoute.Fan : AppRoute.FanProfile}/${id}`);
                break;
            default:
                break;
        }
    };

    setFilter = (filter: FilterOptions) => {
        const { params } = this.state;

        this.setState({
            userTypes: filter.userTypes,
            sports: filter.sports,
            profileStatus: filter.profileStatus,
            params: {
                ...params,
                _page: 0,
            },
        }, this.fetchUsers);
    }

    toggleVisibility = async (userId: number): Promise<void> => {
        const { t, toggleUserVisibility } = this.props;

        await toggleUserVisibility(userId, this.fetchUsers, (response) => {
            let message = t('admin.users.failToggle');
            if (hasAnyErrors(response?.errors)) {
                const error = response.errors[0];
                message = t(`errors.${ErrorCode[error.errorCode]}`);
            }
            displayError({ message });
        });
    };

    renderTable = () => {
        const { t, user: adminUser } = this.props;
        const {
            searchValue, users, totalUsers, params: { _page: page, _limit: limit },
        } = this.state;

        return (
            <div className="admin-screen__content__body">
                <div className="admin-screen__content__body__table-header">
                    <div className="admin-screen__content__body__table-header__title">
                        <h4>{t('admin.users.users')}</h4>
                    </div>
                    <div className="admin-screen__content__body__table-header__search">
                        <SearchInput
                            searchValue={searchValue}
                            onSearchSubmit={this.onSearchSubmit}
                            isAdmin
                        />
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>{t('admin.users.actions')}</th>
                            <th>
                                <button type="button" onClick={(): void => this.onSortChange('name')}>
                                    {t('admin.users.name')}
                                </button>
                            </th>
                            <th>
                                <button type="button" onClick={(): void => this.onSortChange('email')}>
                                    {t('admin.users.contact')}
                                </button>
                            </th>
                            <th>
                                <button type="button" onClick={(): void => this.onSortChange('role')}>
                                    {t('admin.users.access')}
                                </button>
                            </th>
                            <th>
                                <button type="button" onClick={(): void => this.onSortChange('hiddenProfile')}>
                                    {t('admin.users.public')}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 ? (
                            users.map((user) => (
                                <tr key={user.id}>
                                    <td className="table-action">
                                        {adminUser && adminUser.id !== user.id && (
                                            <img className="tab-item" src={iconDelete} alt="delete" onClick={(): void => this.onDeleteUserIconClick(user)} />
                                        )}
                                        {user.role !== UserRoles.Admin && (
                                            <React.Fragment>
                                                <IconEdit fill="#cccccc" onClick={(): void => this.handleUserClickNavigation(user, false)} testId="user-edit" />
                                                <IconCreditCard onClick={(): void => this.onCreditCardClick(user)} />
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td>
                                        <div className="clickable" aria-label={t('admin.users.table')} data-testid="user-info" onClick={() => { this.handleUserClickNavigation(user, true); }}>
                                            <img src={fileURL(user.avatar?.id)} alt="avatar" />
                                            {`${user.name} ${user?.lastName || ''}`}
                                        </div>
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        {user.hiddenProfile ? (
                                            <IconCheckbox
                                                testId="users-checkbox"
                                                fill="#278982"
                                                onClick={(): Promise<void> => this.toggleVisibility(user.id)}
                                            />
                                        ) : (
                                            <IconCheckedBox
                                                testId="users-checkbox"
                                                fill="#278982"
                                                onClick={(): Promise<void> => this.toggleVisibility(user.id)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} className="no-data">
                                    {t('admin.users.noData')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    count={totalUsers}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </div>
        );
    }

    render(): React.ReactNode {
        const { t } = this.props;
        const {
            showInvite, showConfirmationModal, showCrediManagementModal, selectedUser,
        } = this.state;

        return (
            <div className="admin-screen" data-testid="athlete-screen">
                {showInvite && <InviteScreen close={() => this.setState({ showInvite: false })} />}
                {showConfirmationModal && (
                    <ConfirmationModal
                        onAccept={this.deleteUser}
                        onClose={() => this.setState({ showConfirmationModal: false })}
                        titleTranslationKey={{ key: 'profile.deleteConfirmation', params: { name: selectedUser?.name } }}
                        cancelTranslationKey="gallery.cancel"
                        deleteTranslationKey="gallery.delete"
                    />
                )}
                {showCrediManagementModal && selectedUser && (
                    <CreditEditModal
                        user={selectedUser}
                        onClose={(): void => {
                            this.fetchUsers();
                            this.setState({ showCrediManagementModal: false });
                        }}
                    />
                )}
                <div className="admin-screen__header">
                    <h3 className="title">{t('admin.users.title')}</h3>
                    <button
                        type="button"
                        onClick={() => this.setState({ showInvite: true })}
                        className="btn btn--primary-inverse"
                        aria-label="invite-user"
                    >
                        <img src={add} alt="add" />
                    </button>
                </div>
                <div className="admin-screen__content">
                    <Filter
                        onFilterSubmit={this.setFilter}
                        isAdmin
                    />
                    {this.renderTable()}
                </div>
            </div>

        );
    }
}

export default withRouter(withAuthenticationContext(withTranslationContext(withUserContext(UsersScreen))));
