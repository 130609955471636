/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { GeneralContextProvider } from './GeneralContext';
import { regionsURL } from '../../../services/location';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export class GeneralController extends Component<Props> {
    getRegions = async (code: string): Promise<string[]> => {
        try {
            const { data } = await axios.get(regionsURL(code));
            return data;
        } catch {
            return [];
        }
    }

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <GeneralContextProvider
                value={{
                    getRegions: this.getRegions,
                }}
            >
                {children}
            </GeneralContextProvider>
        );
    }
}

export const ConnectedGeneralController = (GeneralController);
