/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { displayError } from '../../utils/notifications';
import { MatchParams } from '../../constants/misc';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { withWalletContext, WalletContext } from '../controllers/wallet/WalletContext';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import creditsIcon from '../../assets/images/credits_icon.svg';
import silver from '../../assets/images/silver.svg';
import gold from '../../assets/images/gold.svg';
import diamond from '../../assets/images/diamond.svg';
import exit from '../../assets/images/exit.svg';
import topSeller from '../../assets/images/top_seller.svg';
import { AppRoute } from '../../constants/routes';
import { TabType } from '../../utils/misc';
import {
    diamondPack, goldPack, Pack, silverPack,
} from '../../constants/market';
import Can from '../containers/Can';
import { PermissionKey } from '../../constants/authorization';
import CreditsPaymentModal from '../elements/CreditsPaymentModal';

interface RouteState {
    from?: string;
}

interface OwnProps extends RouteComponentProps<MatchParams, Record<string, unknown>, RouteState>, AuthenticationContext, WalletContext, TranslationContext {}

type Props = OwnProps;

interface OwnState {
    showTermsAndConditions: boolean;
    sliderPosition: number;
    packSelected: Pack | null;
}

type State = OwnState;

const initialState: State = {
    showTermsAndConditions: false,
    sliderPosition: 0,
    packSelected: null,
};

class MarketScreen extends Component<Props, State> {
    state = initialState;

    componentDidMount = (): void => {
        const { history, openLogin } = this.props;
        window.scrollTo(0, 0);

        if (history.location.state && matchPath(String(history.location.state.from), { path: AppRoute.ValidateAcount })) {
            openLogin();
        }
    }

    onInvalidClick = (e: React.MouseEvent): void => {
        const { t } = this.props;
        e.preventDefault();
        displayError({ message: t('general.unavailable') });
    };

    onOneTimeBuyClick = async (e: React.MouseEvent, packSelected: Pack) => {
        const { t, isAuthenticated } = this.props;
        if (isAuthenticated) {
            this.setState({
                packSelected,
            });
        } else {
            displayError({ message: t('errors.loginOrRegister') });
        }
    };
    
    onExitStore = (): void => {
        const { history } = this.props;
        if (history.location.state && history.location.state.from === AppRoute.ValidateAcount) {
            history.push(AppRoute.Index);
        } else {
            history.goBack();
        }
    }

    onSliderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ sliderPosition: Number(e.currentTarget.value) });
    }

    onShowTermsAndConditions = (): void => {
        this.setState({ showTermsAndConditions: true });
    }

    renderCreditsInformation = (): ReactNode => {
        const { t } = this.props;
        const { sliderPosition } = this.state;
        return (
            <>
                <div className="header">
                    <ReactSVG wrapper="span" src={creditsIcon} />
                    <h2>{t('market.creditsPacks.title')}</h2>
                    <p>{t('market.creditsPacks.buyCredits')} <br />{t('market.creditsPacks.visit')} <a href={AppRoute.SportNetwork} rel="noopener noreferrer" target="_blank">{t('market.creditsPacks.network')}</a>{t('market.creditsPacks.chooseBenefits')}</p>
                </div>
                <div className="market-screen__cards">
                    <div className="card">
                        <div className="planName">
                            <h4><ReactSVG src={silver} />{t('market.creditsPacks.silver')}</h4>
                        </div>
                        <div className="creditAmount">
                            <div>
                                <p>
                                    {silverPack.credits}
                                </p>
                                <p>{t('market.credits')}</p>
                            </div>
                        </div>
                        <p>{t('market.creditsPacks.silverInformation')}</p>
                        <div className="priceBox">
                            <hr />
                            <div className="priceTerms priceTerms--credits ">
                                <div><p className="price">{silverPack.price}€</p></div>
                                <div>
                                    <p className="duration">{t('market.oneOff')}</p>
                                </div>
                            </div>
                            <Can
                                actions={[PermissionKey.ADMIN]}
                                no={() => (
                                    <div>
                                        <button
                                            className="subscribe"
                                            type="button"
                                            data-testid="button-buy-silverPack"
                                            onClick={(event) => this.onOneTimeBuyClick(event, silverPack)}
                                        >
                                            {t('market.buy')}
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="planName planName--gold">
                            <div className="topSeller topSeller--gold">
                                <ReactSVG wrapper="span" src={topSeller} />
                            </div>
                            <h4><ReactSVG src={gold} />{t('market.creditsPacks.gold')}</h4>
                        </div>
                        <div className="creditAmount creditAmount--gold">
                            <div>
                                <p>
                                    {goldPack.credits}
                                </p>
                                <p>{t('market.credits')}</p>
                            </div>
                        </div>
                        <p>{t('market.creditsPacks.goldInformation')}</p>
                        <div className="priceBox">
                            <hr />
                            <div className="priceTerms priceTerms--credits  priceTerms--gold">
                                <div><p className="price">{goldPack.price}€</p></div>
                                <p className="duration">{t('market.oneOff')}</p>
                            </div>
                            <Can
                                actions={[PermissionKey.ADMIN]}
                                no={() => (
                                    <div>
                                        <button
                                            className="subscribe subscribe--gold"
                                            type="button"
                                            data-testid="button-buy-goldPack"
                                            onClick={(event) => this.onOneTimeBuyClick(event, goldPack)}
                                        >
                                            {t('market.buy')}
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="planName planName--diamond">
                            <h4><ReactSVG src={diamond} />{t('market.creditsPacks.diamond')}</h4>
                        </div>
                        <div className="creditAmount creditAmount--diamond">
                            <div>
                                <p>
                                    {diamondPack[sliderPosition].credits}
                                </p>
                                <p>{t('market.credits')} {sliderPosition === 0 && (<span>{t('market.minAmount')}</span>)}</p>
                            </div>
                            <span className="chooseCredits">{t('market.creditsPacks.chooseCredits')}</span>
                        </div>
                        <div />
                        <div className="priceBox">
                            <input className="slider" value={sliderPosition} type="range" min="0" max={diamondPack.length - 1} step="1" onChange={this.onSliderChange} />
                            <div className="priceTerms priceTerms--credits  priceTerms--diamond">
                                <div><p className="price">{diamondPack[sliderPosition].price}€</p></div>
                                <p className="duration">{t('market.oneOff')}</p>
                            </div>
                            <Can
                                actions={[PermissionKey.ADMIN]}
                                no={() => (
                                    <div>
                                        <button
                                            className="subscribe subscribe--diamond"
                                            type="button"
                                            data-testid="button-buy-diamondPack"
                                            onClick={(event) => this.onOneTimeBuyClick(event, diamondPack[sliderPosition])}
                                        >
                                            {t('market.buy')}
                                        </button>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render(): ReactNode {
        const { t } = this.props;
        const { showTermsAndConditions, packSelected } = this.state;

        return (
            <>
                {packSelected && (
                    <CreditsPaymentModal
                        onClose={() => this.setState({ packSelected: null })}
                        pack={packSelected}
                    />
                )}
                <div className="market-screen">
                    {this.renderCreditsInformation()}
                    <p className="termsAndConditions">{t('market.allPrices')}<span onClick={this.onShowTermsAndConditions}>{t('market.t&c')}</span>{t('market.apply')}</p>
                    <button type="button" className="exitStore__button" onClick={this.onExitStore}>
                        <ReactSVG wrapper="span" src={exit} />
                        <span>{t('market.exitStore')}</span>
                    </button>
                </div>

                {showTermsAndConditions && (
                    <TermsAndConditionsScreen
                        close={(): void => this.setState({ showTermsAndConditions: false })}
                        tab={TabType.TERMS_CONDITIONS}
                        inRegister={false}
                    />
                )}
            </>
        );
    }
}

export default withRouter(
    withAuthenticationContext(
        withWalletContext(
            withTranslationContext(
                MarketScreen,
            ),
        ),
    ),
);
