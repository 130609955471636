/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import logo from '../../assets/images/logo_center.svg';
import closeIcon from '../../assets/images/icon_close_green.svg';
import repeat from '../../assets/images/repeat.svg';
import arrowForward from '../../assets/images/arrow_forward.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { SportestContext, withSportestContext } from '../controllers/sportest/SportestContext';
import { DropdownOption, KeyedObject, MatchParams } from '../../constants/misc';
import {
    SPORTEST_TYPES, SPORTEST_QUESTIONS, SPONSOR_TYPE, SportestCreate,
} from '../../constants/sportest';
import Dropdown from '../elements/Dropdown';
import InputError from '../elements/FormFieldError';
import { displayError } from '../../utils/notifications';
import { ErrorCode } from '../../constants/errors';
import { getErrorsForField, hasAnyErrors } from '../../utils/validations';
import { UserRoles } from '../../types/user';

interface OwnProps {
    isFetching: boolean;
    close: () => void;
    openRegistration: () => void;
    role?: UserRoles;
    athleteModality?: string;
}

type Props = OwnProps & TranslationContext & RouteComponentProps<MatchParams> & SportestContext;

interface Options {
    [key: string]: DropdownOption[];
}

interface OwnState {
    selectedTab?: Tab;
    score: number;
    done: boolean;
    fields: SportestCreate;
    errors: KeyedObject | null;
    options: Options;
    loaded: boolean;
    defaultAthleteModality: DropdownOption | null;
}

interface Tab {
    label: string;
    value: SPORTEST_TYPES;
    create: string;
}

interface Answer {
    id: number;
    name: string;
    sportestAnswerOptionType: string;
}

const initialState: OwnState = {
    selectedTab: undefined,
    score: 0,
    done: false,
    fields: {
        athleteBestResultEventAnswerId: null,
        athleteBestResultPositionAnswerId: null,
        athleteBudgetAnswerId: null,
        athleteModalityAnswerId: null,
        fanInterest: '',
        fanModalityAnswerId: null,
        sponsorEntityType: null,
        sponsorModalityAnswerId: null,
        sponsorSponsorshipBudgetAnswerId: null,
        sponsorSponsorshipPackAnswerId: null,
        sportestType: SPORTEST_TYPES.ATHLETE,
    },
    errors: null,
    options: {
        ATLHETE_BEST_POSITION: [],
        ATLHETE_BUDGET: [],
        ATLHETE_EVENT: [],
        MODALITY: [],
        SPONSOR_BUDGET: [],
        SPONSOR_PACK: [],
    },
    loaded: false,
    defaultAthleteModality: null,
};

type State = OwnState;

class TestScreen extends Component<Props, State> {
    state = initialState;

    private readonly modalityLabel = 'test.modality';

    private readonly tabs: Tab[] = [
        { label: 'test.tab1', value: SPORTEST_TYPES.ATHLETE, create: 'test.create1' },
        { label: 'test.tab2', value: SPORTEST_TYPES.FAN, create: 'test.create2' },
        { label: 'test.tab3', value: SPORTEST_TYPES.SPONSOR, create: 'test.create3' },
    ];

    componentDidMount() {
        const { role } = this.props;
        this.prepare();

        let selectedTab = this.tabs[0];
        if (role) {
            switch (role) {
                case UserRoles.Fan:
                    [, selectedTab] = this.tabs;
                    break;
                case UserRoles.Sponsor:
                    [,, selectedTab] = this.tabs;
                    break;
                default:
                    break;
            }
        }
        this.switchTab(selectedTab);
    }

    onInputChange = (option: DropdownOption, name: string): void => {
        const { fields } = this.state;

        this.setState({
            fields: {
                ...fields,
                [name]: option,
            },
            done: false,
            score: 0,
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const { submitNewSportest, validateNewSportest } = this.props;
        const { fields } = this.state;

        const errors = validateNewSportest(fields);

        this.setState(
            { errors },
            () => {
                if (!errors) {
                    submitNewSportest(fields, this.onFormSubmitSuccess, this.onFailure);
                }
            },
        );
    };

    onFormSubmitSuccess = (result: number, shouldAppend: boolean) => {
        const { addSportest } = this.props;
        const { fields } = this.state;
        if (shouldAppend) {
            addSportest(fields);
        }
        this.setState({ done: true, score: result });
    };

    onFailure = (errors: KeyedObject) => {
        const { t } = this.props;

        if (errors) {
            if (errors.fields) {
                this.setState({
                    errors: errors.fields,
                });
            }

            if (errors.errors) {
                errors.errors.forEach((error: any) => {
                    displayError({
                        message: t(`errors.${ErrorCode[error.errorCode]}`),
                    });
                });
            }
        }
    };

    onRadioChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
            done: false,
            score: 0,
        });
    };

    onRegisterClick = () => {
        const { openRegistration, close } = this.props;
        openRegistration();
        close();
    };

    goToHome = () => {
        const { close } = this.props;
        close();
    };

    prepare = () => {
        const { fields } = this.state;
        const { getAnswers, athleteModality } = this.props;
        getAnswers().then((answers) => {
            const options: Options = {
                ATLHETE_BEST_POSITION: [],
                ATLHETE_BUDGET: [],
                ATLHETE_EVENT: [],
                MODALITY: [],
                SPONSOR_BUDGET: [],
                SPONSOR_PACK: [],
            };
            if (answers) {
                answers.forEach((answer: Answer) => {
                    options[answer.sportestAnswerOptionType].push({ label: answer.name, value: answer.id });
                });
            }

            if (athleteModality) {
                const defaultAthleteModality = options[SPORTEST_QUESTIONS.MODALITY].find(
                    (modality) => String(modality.label).toUpperCase() === athleteModality,
                );

                this.setState({
                    options,
                    loaded: true,
                    defaultAthleteModality: defaultAthleteModality || null,
                    fields: {
                        ...fields,
                        athleteModalityAnswerId: defaultAthleteModality || initialState.fields.athleteModalityAnswerId,
                    },
                });
            } else {
                this.setState({
                    options,
                    loaded: true,
                });
            }
        });
    };

    getSponsorOptions = () => {
        const { t } = this.props;
        return [
            { label: t(`test.sponsor.${SPONSOR_TYPE.SPORTS_AGENCY}`), value: SPONSOR_TYPE.SPORTS_AGENCY },
            { label: t(`test.sponsor.${SPONSOR_TYPE.SPORTS_CLUB}`), value: SPONSOR_TYPE.SPORTS_CLUB },
            { label: t(`test.sponsor.${SPONSOR_TYPE.SPORTS_INSTITUTION}`), value: SPONSOR_TYPE.SPORTS_INSTITUTION },
            { label: t(`test.sponsor.${SPONSOR_TYPE.SPORTS_ORGANIZER}`), value: SPONSOR_TYPE.SPORTS_ORGANIZER },
            { label: t(`test.sponsor.${SPONSOR_TYPE.PRIVATE_COMPANY}`), value: SPONSOR_TYPE.PRIVATE_COMPANY },
            { label: t(`test.sponsor.${SPONSOR_TYPE.OTHER}`), value: SPONSOR_TYPE.OTHER },
        ];
    };

    switchTab = (tab: Tab) => {
        this.setState((prevState: State) => ({
            selectedTab: tab, done: false, score: 0, fields: { ...prevState.fields, sportestType: tab.value },
        }));
    };

    renderAthleteTab = (): React.ReactNode => {
        const { t, createSportestFetching } = this.props;
        const {
            selectedTab, fields, options, errors, loaded, defaultAthleteModality,
        } = this.state;

        if (selectedTab?.value === SPORTEST_TYPES.ATHLETE && loaded) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>{t(this.modalityLabel)}</h3>
                            <Dropdown
                                label=" "
                                name="athleteModalityAnswerId"
                                options={options[SPORTEST_QUESTIONS.MODALITY]}
                                value={fields.athleteModalityAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching || !!defaultAthleteModality}
                            />
                        </div>
                        <div className="col-sm-12">
                            <h3>{t('test.budget')}</h3>
                            <Dropdown
                                label={t('test.athleteBudgetAnswerId')}
                                name="athleteBudgetAnswerId"
                                options={options[SPORTEST_QUESTIONS.ATLHETE_BUDGET]}
                                value={fields.athleteBudgetAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="col-sm-12">{t('test.result')}</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Dropdown
                                label={t('test.athleteBestResultEventAnswerId')}
                                name="athleteBestResultEventAnswerId"
                                options={options[SPORTEST_QUESTIONS.ATLHETE_EVENT]}
                                value={fields.athleteBestResultEventAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Dropdown
                                label={t('test.athleteBestResultPositionAnswerId')}
                                name="athleteBestResultPositionAnswerId"
                                options={options[SPORTEST_QUESTIONS.ATLHETE_BEST_POSITION]}
                                value={fields.athleteBestResultPositionAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                    </div>
                </>
            );
        }
        return <React.Fragment />;
    };

    renderFanTab = (): React.ReactNode => {
        const {
            selectedTab, fields, options, errors, loaded,
        } = this.state;
        const { t, createSportestFetching } = this.props;

        const fieldErrors = getErrorsForField('fanInterest', errors);
        const hasErrors = hasAnyErrors(fieldErrors);

        if (selectedTab?.value === SPORTEST_TYPES.FAN && loaded) {
            return (
                <>
                    <div className="row">
                        <h3 className="col-sm-12">{t(this.modalityLabel)}</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <Dropdown
                                name="fanModalityAnswerId"
                                options={options[SPORTEST_QUESTIONS.MODALITY]}
                                value={fields.fanModalityAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="col-sm-12">{t('test.favorite')}</h3>
                    </div>
                    <div className="row">
                        <div className="form-field">
                            {['A', 'B', 'C', 'D'].map((option) => (
                                <div className="radio-input col-sm-12" key={option}>
                                    <input
                                        id={option}
                                        type="radio"
                                        value={option}
                                        name="fanInterest"
                                        onChange={this.onRadioChange}
                                        checked={fields.fanInterest === option}
                                        disabled={createSportestFetching}
                                        data-testid={option}
                                    />
                                    <label htmlFor={option}>{t(`test.option${option}`)}</label>
                                </div>
                            ))}
                            {hasErrors && (
                                <div className="col-sm-12">
                                    <InputError errors={fieldErrors} field="fanInterest" />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return <React.Fragment />;
    };

    renderSponsorTab = (): React.ReactNode => {
        const {
            selectedTab, fields, options, errors, loaded,
        } = this.state;
        const { t, createSportestFetching } = this.props;
        if (selectedTab?.value === SPORTEST_TYPES.SPONSOR && loaded) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <h3>{t(this.modalityLabel)}</h3>
                            <Dropdown
                                name="sponsorModalityAnswerId"
                                options={options[SPORTEST_QUESTIONS.MODALITY]}
                                value={fields.sponsorModalityAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <h3>{t('test.legal')}</h3>
                            <Dropdown
                                name="sponsorEntityType"
                                options={this.getSponsorOptions()}
                                value={fields.sponsorEntityType}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h3 className="col-sm-12">{t('test.sponsorship')}</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <Dropdown
                                label={t('test.sponsorSponsorshipPackAnswerId')}
                                name="sponsorSponsorshipPackAnswerId"
                                options={options[SPORTEST_QUESTIONS.SPONSOR_PACK]}
                                value={fields.sponsorSponsorshipPackAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <Dropdown
                                label={t('test.sponsorSponsorshipBudgetAnswerId')}
                                name="sponsorSponsorshipBudgetAnswerId"
                                options={options[SPORTEST_QUESTIONS.SPONSOR_BUDGET]}
                                value={fields.sponsorSponsorshipBudgetAnswerId}
                                onChange={this.onInputChange}
                                errors={errors}
                                disabled={createSportestFetching}
                            />
                        </div>
                    </div>
                </>
            );
        }
        return <React.Fragment />;
    };

    render(): React.ReactNode {
        const { t } = this.props;

        const {
            selectedTab, score, done, fields,
        } = this.state;

        if (!selectedTab) return null;

        return (
            <div className="modal" data-testid="sport-test-modal">
                <div className="modal__test" />
                <div className="modal__content__test test">
                    <div className="test-screen">
                        <div className="test-screen__content">
                            <span className="green-title">{t('test.title')}</span>
                            <img
                                src={closeIcon}
                                alt=""
                                className="close"
                                onClick={this.goToHome}
                                data-testid="close-modal"
                            />
                            <img src={logo} alt="" className="logo" />
                            <div className="test-screen__content__tabs underline-tabs">
                                {this.tabs.map((tab) => {
                                    const classes = done ? ['tab--disabled'] : [];
                                    if (selectedTab.value === tab.value) {
                                        classes.push('tab--active');
                                    }
                                    return (
                                        <span
                                            className={classes.join(' ')}
                                            onClick={() => this.switchTab(tab)}
                                            key={tab.value}
                                            data-testid={tab.value}
                                        >
                                            {t(tab.label)}
                                        </span>
                                    );
                                })}
                            </div>
                            <form onSubmit={this.onSubmit}>
                                {this.renderAthleteTab()}
                                {this.renderFanTab()}
                                {this.renderSponsorTab()}
                                <div className="test-screen__content__result-bar">
                                    <div className="test-screen__content__result-bar__overflow">
                                        <div
                                            className={`test-screen__content__result-bar__overflow__bar ${
                                                score > 6
                                                    ? 'test-screen__content__result-bar__overflow__bar--high-score'
                                                    : ''
                                            }`}
                                            style={{ transform: `rotate(${(180 / 10) * score + 45}deg)` }}
                                        />
                                        <span className="test-screen__content__result-bar__overflow__score">
                                            {score} <span>|</span> 10
                                        </span>
                                        <button type="submit" className="btn btn--primary-inverse" disabled={done}>
                                            {done ? t('test.done') : t('test.test')}
                                        </button>
                                    </div>
                                </div>
                            </form>

                            {done && (
                                <span
                                    data-testid="sportest-register"
                                    className="test-screen__content__create"
                                    onClick={this.onRegisterClick}
                                >
                                    {t(selectedTab.create)}
                                </span>
                            )}

                            {done && (
                                <span
                                    className="test-screen__content__repeat"
                                    onClick={() => this.setState({
                                        score: 0,
                                        done: false,
                                        fields: { ...initialState.fields, sportestType: fields.sportestType },
                                    })
                                    }
                                    data-testid="sportest-retry"
                                >
                                    <img src={repeat} alt="repeat" />
                                    {t('test.repeat')}
                                </span>
                            )}

                            <div className="green-btn" onClick={this.goToHome}>
                                {t('test.next')}
                                <img src={arrowForward} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withSportestContext(withRouter(TestScreen)));
