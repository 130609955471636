/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import { UserRoles } from '../../types/user';
import AthleteSupporterModal from '../elements/AthletePendingSupportModal';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteProps, AuthenticationContext}
 * @param {UserRoles[]} rolesAllowed
 */
interface OwnProps {
    rolesAllowed?: UserRoles[];
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps & RouteProps & AuthenticationContext;

/**
 * extends route to check if use is authenticated, if not it redirects to login
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
const AuthenticatedRoute: React.FC<Props> = (props: Props) => {
    const {
        isAuthenticated, user, rolesAllowed, location,
    } = props;

    if (!isAuthenticated || (rolesAllowed && user?.role && !rolesAllowed.includes(user.role))) {
        const renderComponent = () => (
            <Redirect
                to={{
                    pathname: AppRoute.Index,
                    state: {
                        from: location,
                    },
                }}
            />
        );

        return <Route {...props} component={renderComponent} render={undefined} />;
    }

    return (
        <>
            <Route {...props} />
            <AthleteSupporterModal />
        </>
    );
};

export default withAuthenticationContext(AuthenticatedRoute);
