/**
*
* @Copyright 2023 VOID SOFTWARE, S.A.
*
*/

import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { Tab, Tabs } from '@material-ui/core';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/horizontal_logo.svg';
import IconTimes from '../assets/IconTimes';
import { Pack } from '../../constants/market';
import FormField from './FormField';
import { KeyedObject } from '../../constants/misc';
import { REGEX, validateForm, ValidationType } from '../../utils/validations';
import { WalletContext, withWalletContext } from '../controllers/wallet/WalletContext';
import { IconCheckCircleFilled } from '../assets/IconCheckCircleFilled';
import { AppRoute } from '../../constants/routes';
import IconMBWay from '../assets/IconMBWay';
import loader from '../../assets/images/loader.svg';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../types/user';
import { PHONE_NUMBER_LENGHT } from '../../types/credit';
import { PaymentType } from '../../types/payments';
import { IS_CC_PAYMENTS_ENABLED } from '../../settings';
import { IconCreditCard } from '../assets/IconCreditCard';

interface OwnProps extends TranslationContext, WalletContext, AuthenticationContext {
    onClose: () => void;
    pack: Pack;
}

const CreditsPaymentModal: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t, onClose, pack, submitPayment, user, submitCreditCardPaymentRequest,
    } = props;

    const history = useHistory();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [errors, setErrors] = useState<KeyedObject>({});
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentType>(PaymentType.MBWAY);

    const onSubmitSuccess = () => {
        setIsFetching(false);
        setIsSuccessful(true);
    };

    const onSubmitFailure = (formErrors: KeyedObject) => {
        setIsSuccessful(false);
        setIsFetching(false);
        setErrors(formErrors);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedPaymentMethod === PaymentType.MBWAY) {
            submitMBWayPayment();
        } else if (selectedPaymentMethod === PaymentType.CREDIT_CARD) {
            submitCrediCardPayment();
        }
    };

    const onRedirectSuccess = () => {
        setIsRedirecting(false);
    };

    const submitCrediCardPayment = () => {
        setIsRedirecting(true);
        submitCreditCardPaymentRequest(pack, onRedirectSuccess);
    };

    const submitMBWayPayment = () => {
        const validationErrors = validateForm({ phoneNumber }, {
            phoneNumber: {
                validations: [ValidationType.NotBlank, ValidationType.Regex, ValidationType.Max],
                regex: REGEX.PHONE_NUMBER,
                max: PHONE_NUMBER_LENGHT.MAX,
            },
        });

        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }

        setIsFetching(true);
        submitPayment({
            phoneNumber,
            creditAmount: pack.credits,
        }, onSubmitSuccess, onSubmitFailure);
    };

    const redirectProfileRoute = () => {
        switch (user?.role) {
            case UserRoles.Sponsor:
                history.push(AppRoute.SponsorProfile);
                break;
            case UserRoles.Fan:
                history.push(AppRoute.FanProfile);
                break;
            case UserRoles.Manager:
                history.push(AppRoute.ManagerProfile);
                break;
            default:
                history.push(AppRoute.AthleteProfile);
        }
    };

    const handlePaymentMethodChange = (newPaymentMethod: PaymentType) => {
        setSelectedPaymentMethod(newPaymentMethod);
    };

    const renderPaymentTabs = () => {
        return (
            <Tabs value={selectedPaymentMethod} onChange={(_, newTab) => handlePaymentMethodChange(newTab)}>
                <Tab
                    label={(
                        <>
                            <IconMBWay />
                            <p>{t('market.payWithMbWay')}</p>
                        </>
                    )}
                    value={PaymentType.MBWAY}
                />
                {IS_CC_PAYMENTS_ENABLED && (
                    <Tab
                        label={(
                            <>
                                <IconCreditCard />
                                <p>{t('market.payWithCC')}</p>
                            </>
                        )}
                        value={PaymentType.CREDIT_CARD}
                    />
                )}
                
            </Tabs>
        );
    };

    const renderMBWayForm = () => {
        return (
            <div className="modal--secondary__form">
                <FormField
                    label={t('market.phoneNumber')}
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={({ currentTarget: { value } }): void => setPhoneNumber(value)}
                    placeholder=""
                    errors={errors}
                    disabled={isFetching}
                />
            </div>
        );
    };

    const renderSelectedPaymentForm = () => {
        switch (selectedPaymentMethod) {
            case PaymentType.MBWAY:
                return renderMBWayForm();
            case PaymentType.CREDIT_CARD:
                return (<></>);
            default:
                return renderMBWayForm();
        }
    };

    return (
        <div className="modal" data-testid="credits-payment-modal">
            {(isFetching || isRedirecting) && (
                <div className="loader-backdrop">
                    <div className="loader" data-testid="loader">
                        <ReactSVG wrapper="span" src={loader} />
                    </div>
                </div>
            )}
            <div className="modal__backdrop" />
            <form className="modal--secondary modal__content" onSubmit={onSubmit}>
                <div className="modal--secondary__header">
                    <img src={logo} alt="logo" />
                    <button type="button" onClick={onClose}>
                        <IconTimes fill="#278982" />
                    </button>
                </div>
                {isSuccessful ? (
                    <div className="modal--secondary__success">
                        <IconCheckCircleFilled />
                        <p>{t('market.mbWaySuccessPaymentInfo1')}</p>
                        <p>{t('market.mbWaySuccessPaymentInfo2')}</p>
                    </div>
                ) : (
                    <React.Fragment>
                        {renderPaymentTabs()}
                        <div className="modal--secondary__credits-payment-info">
                            <span>{t('market.paymentInformationMessage')}</span>
                            <span className="bold">{` ${pack.credits} ${t('market.credits')} `}</span>
                            <span>{t('market.for')}</span>
                            <span className="bold">{` ${pack.price}€`}</span>
                        </div>
                        {renderSelectedPaymentForm()}
                    </React.Fragment>
                )}
                {isSuccessful ? (
                    <div className="modal--secondary__actions modal--secondary__success">
                        <button
                            className="btn btn--primary-inverse"
                            type="button"
                            onClick={redirectProfileRoute}
                        >
                            {t('general.ok')}
                        </button>
                    </div>
                ) : (
                    <div className="modal--secondary__actions modal--secondary__actions--buy-credits-modal">
                        <button
                            className="btn btn--primary"
                            type="button"
                            onClick={onClose}
                            disabled={isFetching}
                        >
                            {t('general.cancel')}
                        </button>
                        <button
                            className="btn btn--primary-inverse"
                            type="submit"
                            disabled={isFetching}
                        >
                            {selectedPaymentMethod === PaymentType.MBWAY ? t('general.confirm') : t('general.proceed')}
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default withTranslationContext(withWalletContext(withAuthenticationContext(CreditsPaymentModal)));
