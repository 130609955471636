/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox, Drawer, FormControlLabel,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useEffect, useState } from 'react';
import IconFilter from '../assets/IconFilter';
import close from '../../assets/images/icon_close_green.svg';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ProfileStatus, UserFilter } from '../../types/user';
import { SportTypes } from '../../types/sport';
import IconDropdown from '../assets/IconDropdown';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import { CountryCode, CountryType } from '../../types/country';
import { FilterOptions } from '../../types/filter';

interface Props {
    onFilterSubmit: (filter: FilterOptions) => void;
    isAdmin?: boolean;
}

type OwnProps = TranslationContext & GeneralContext & Props;

const Filter: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        onFilterSubmit,
        getRegions,
        isAdmin,
    } = props;
    
    const [isSidebar, setIsSidebar] = useState(false);
    const [country, setCountry] = useState<CountryType | null>(null);
    const [profileStatus, setProfileStatus] = useState<ProfileStatus | null>(null);
    const [region, setRegion] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [sports, setSports] = useState<SportTypes[]>([]);
    const [userTypes, setUserTypes] = useState<UserFilter[]>([UserFilter.ALL]);
    
    const prefix = isSidebar ? 'filter__sidebar' : 'filter';
    
    useEffect(() => {
        if (country !== null) fetchRegion(country);
        // eslint-disable-next-line
    }, [country]);

    const fetchRegion = async (countrySelected: CountryType) => {
        const data = await getRegions(countrySelected?.code);
        setRegion(data);
    };
    
    const onFilterClick = () => {
        setIsSidebar(!isSidebar);
    };

    const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>, status: ProfileStatus) => {
        if (event.target.checked) return setProfileStatus(status);
            
        setProfileStatus(null);
    };

    const handleChangeRole = async (event: React.ChangeEvent<HTMLInputElement>, userRoles: UserFilter) => {
        if (event.target.checked) {
            setUserTypes((prevRoles) => {
                if (isAdmin) {
                    const updatedRoles = prevRoles.includes(UserFilter.ALL)
                        ? [userRoles]
                        : [...prevRoles, userRoles];
                    return updatedRoles.filter((role) => role !== UserFilter.ALL);
                }
                
                if (userRoles === UserFilter.SPONSOR) {
                    setSports([]);
                }

                return [userRoles];
            });
        } else {
            setUserTypes((prevRoles) => {
                const updatedUserRoles = prevRoles.filter((role) => role !== userRoles);
                 
                if (updatedUserRoles.length === 0) {
                    setUserTypes([UserFilter.ALL]);
                }

                return updatedUserRoles;
            });
        }
    };
    
    const handleChangeSport = (event: React.ChangeEvent<HTMLInputElement>, sportType: SportTypes) => {
        if (event.target.checked) {
            setSports((prevSports) => {
                const updatedSports = [...prevSports, sportType];
      
                if (updatedSports.length > 0) {
                    setUserTypes([UserFilter.ATHLETE]);
                }
      
                return updatedSports;
            });
        } else {
            setSports((prevSports) => {
                const updatedSports = prevSports.filter((sport) => sport !== sportType);
      
                if (updatedSports.length === 0) {
                    setUserTypes([UserFilter.ALL]);
                }
      
                return updatedSports;
            });
        }
    };

    const submitFilters = () => {
        const filter: FilterOptions = {
            userTypes,
            sports,
            country: isAdmin ? null : country,
            regions: isAdmin ? [] : selectedRegion,
            profileStatus: isAdmin ? profileStatus : null,
        };

        onFilterSubmit(filter);

        if (isSidebar) {
            setIsSidebar(!isSidebar);
        }
    };

    const renderLocation = () => {
        const countryOptions = Object.values(CountryCode).map((code) => ({
            code,
            name: t(`countries.${code}`),
        }));

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconDropdown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {t('filter.location.location')}
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`${prefix}__body__location`}>
                        <Autocomplete
                            options={countryOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.code === value.code}
                            value={country}
                            onChange={(event, newValue) => {
                                setCountry(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label={t('filter.location.country')} variant="outlined" />}
                            size="small"
                        />
                        {(country?.code === CountryCode.ES || country?.code === CountryCode.PT) && (
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                options={region}
                                getOptionLabel={(option) => option}
                                getOptionSelected={(option, value) => option === value}
                                value={selectedRegion}
                                onChange={(event, newValue) => {
                                    setSelectedRegion(newValue);
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={t('filter.location.region')} />
                                )}
                                size="small"
                            />
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderProfileStatus = () => {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconDropdown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {t('filter.profile.profile')}
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`${prefix}__body__profile`}>
                        {Object.values(ProfileStatus).map((status: ProfileStatus) => (
                            <FormControlLabel
                                key={status}
                                label={t(`filter.profile.${status}`)}
                                control={(
                                    <Checkbox
                                        aria-label={`checkbox-${status}`}
                                        checked={profileStatus === status}
                                        onChange={(event) => handleChangeStatus(event, status)}
                                    />
                                )}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderRoles = () => {
        const filters = isAdmin
            ? Object.values(UserFilter).filter((userFilter) => userFilter !== UserFilter.ALL)
            : Object.values(UserFilter).filter((userFilter) => userFilter !== UserFilter.ALL && userFilter !== UserFilter.ADMIN && userFilter !== UserFilter.FAN && userFilter !== UserFilter.MANAGER);

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconDropdown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {t('filter.roles.roles')}
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`${prefix}__body__roles`}>
                        {filters.map((userFilter: UserFilter) => (
                            <FormControlLabel
                                key={userFilter}
                                label={t(`filter.roles.${userFilter}`)}
                                control={(
                                    <Checkbox
                                        aria-label={`checkbox-${userFilter}`}
                                        checked={userTypes.includes(userFilter)}
                                        onChange={(event) => handleChangeRole(event, userFilter)}
                                    />
                                )}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderSports = () => {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<IconDropdown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {t('filter.sports')}
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`${prefix}__body__sports`}>
                        {Object.values(SportTypes).filter((sportType) => sportType !== SportTypes.NA).map((sportList: SportTypes) => (
                            <FormControlLabel
                                key={sportList}
                                label={t(`sports.${sportList}`)}
                                control={(
                                    <Checkbox
                                        aria-label={`checkbox-${sportList}`}
                                        checked={sports.includes(sportList)}
                                        onChange={(event) => handleChangeSport(event, sportList)}
                                    />
                                )}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderFilter = () => {
        return (
            <>
                {isSidebar && (
                    <img alt="" src={close} className="filter__sidebar__close" onClick={() => onFilterClick()} aria-label={t('filter.close')} />
                )}
                <div className={`${prefix}__header`}>
                    <span className="title">
                        {t('filter.title')}
                    </span>
                </div>
                <div className={`${prefix}__body`}>
                    {!isAdmin && (
                        renderLocation()
                    )}
                    {renderRoles()}
                    {renderSports()}
                    {isAdmin && (
                        renderProfileStatus()
                    )}
                </div>
                <div className={`${prefix}__footer`}>
                    <button type="button" className={`${prefix}__footer__btn btn btn--primary-inverse`} onClick={() => submitFilters()} aria-label={t('filter.options')}>
                        {t('filter.title')}
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="filter">
            {!isSidebar && (
                renderFilter()
            )}
            <button className="filter__button btn btn--primary-inverse" type="button" onClick={() => setIsSidebar(true)} aria-label={t('filter.open')}>
                <IconFilter />
                {t('filter.title')}
            </button>
            <Drawer
                anchor="left"
                open={isSidebar}
                onClose={() => onFilterClick()}
            >
                <div className="filter__sidebar">
                    {renderFilter()}
                </div>
            </Drawer>
        </div>
    );
};

export default withTranslationContext(withGeneralContext(Filter));
