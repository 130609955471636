/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

const api = 'locations';

export const regionsURL = (code: string): string => {
    return `${API_URL}/${api}/country/${code}`;
};
