/*
 *
 * @Copyright 2023 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import iconSupport from '../../assets/images/icon_support.svg';
import iconAlert from '../../assets/images/icon_alert_triangle.svg';
import Avatar from './Avatar';
import { AppRoute } from '../../constants/routes';
import Modal from './Modal';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import TermsAndConditionsScreen from '../screens/TermsAndConditionsScreen';
import { TabType } from '../../utils/misc';
import { CREDIT_RANGE_MAX_MIN } from '../../types/credit';
import { WalletContext, withWalletContext } from '../controllers/wallet/WalletContext';
import { displayError, displaySuccess } from '../../utils/notifications';
import { KeyedObject } from '../../constants/misc';
import { UserContext, withUserContext } from '../controllers/user/UserContext';

interface OwnProps extends AuthenticationContext, TranslationContext, WalletContext, UserContext {
    fullNameAthlete: string;
    avatarAthlete: string;
    onClose: () => void;
    athleteId: string;
}

type Props = OwnProps;

const AthleteSupportModal: FunctionComponent<Props> = ({
    fullNameAthlete,
    avatarAthlete,
    onClose,
    user,
    t,
    athleteId,
    submitAthleteSupport,
    getUser,
}: Props) => {
    const [amountCredits, setAmountCredits] = useState(CREDIT_RANGE_MAX_MIN.MIN);
    const [avaliableCreditsUser, setAvaliableCreditsUser] = useState(0);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isAvaliableCreditShort, setIsAvaliableCreditShort] = useState(true);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [isFetching, setIsfetching] = useState(false);

    const getUserData = useCallback(async () => {
        const userData = await getUser(user?.id);
        if (userData && userData.availableCredits) setAvaliableCreditsUser(userData.availableCredits);
    }, [getUser, user]);

    useEffect(() => {
        getUserData();
        if (avaliableCreditsUser > CREDIT_RANGE_MAX_MIN.MAX) {
            setAmountCredits(CREDIT_RANGE_MAX_MIN.MAX);
            setIsAvaliableCreditShort(false);
        } else if (avaliableCreditsUser <= CREDIT_RANGE_MAX_MIN.MAX && avaliableCreditsUser >= CREDIT_RANGE_MAX_MIN.MIN) {
            const amountRangeInterval = Math.trunc(avaliableCreditsUser / CREDIT_RANGE_MAX_MIN.MIN);
            setAmountCredits(amountRangeInterval === 0 ? CREDIT_RANGE_MAX_MIN.MIN : amountRangeInterval * CREDIT_RANGE_MAX_MIN.MIN);
            setIsAvaliableCreditShort(false);
        }
    }, [avaliableCreditsUser, getUserData]);

    const onChangeAmountRange = (amount: number) => {
        setAmountCredits(amount);
        if (avaliableCreditsUser && amount <= avaliableCreditsUser) return setIsAvaliableCreditShort(false);
        setIsAvaliableCreditShort(true);
    };

    const onSuccessSupport = () => {
        displaySuccess({ message: t('athlete.payment.successSupport') });
        onClose();
        setIsfetching(false);
    };

    const onFailureSupport = (error: KeyedObject) => {
        displayError({ message: String(error) });
        setIsfetching(false);
    };

    const handleAthleteSupport = () => {
        setIsfetching(true);
        submitAthleteSupport(athleteId, amountCredits, onSuccessSupport, onFailureSupport);
        getUserData();
    };

    return (
        <Modal
            image={iconSupport}
            title={t('athlete.payment.title')}
            onClose={onClose}
            data-testid="athlete-support-modal"
        >
            <div className="modal--secondary__body__payment-modal">
                <Avatar image={avatarAthlete} />
                <div className="modal--secondary__body__payment-modal__message">
                    <span>{t('athlete.payment.message')}</span>
                    <span className="modal--secondary__body__payment-modal__message--name">{` ${fullNameAthlete}?`}</span>
                </div>
                <input type="range" min={CREDIT_RANGE_MAX_MIN.MIN} max={CREDIT_RANGE_MAX_MIN.MAX} value={amountCredits} onChange={(evt) => onChangeAmountRange(Number(evt.target.value))} step="50" />
                <div className="modal--secondary__body__payment-modal__months">
                    {`${(amountCredits / CREDIT_RANGE_MAX_MIN.MIN).toFixed(0)} ${amountCredits / CREDIT_RANGE_MAX_MIN.MIN > 1 ? t('athlete.payment.months') : t('athlete.payment.month')}`}
                </div>
                <p className="modal--secondary__body__payment-modal__for">{t('athlete.payment.for')}</p>
                <p className="modal--secondary__body__payment-modal__credits">{`${amountCredits} ${t('athlete.payment.credits')}`}</p>
                <div className="modal--secondary__body__payment-modal__credits-avaliable">
                    {isAvaliableCreditShort ? (
                        <>
                            <img src={iconAlert} alt="alert-icon" />
                            <p className="modal--secondary__body__payment-modal__credits-avaliable__alert">
                                {avaliableCreditsUser || avaliableCreditsUser === 0 ? t('athlete.payment.creditsShort', { creditsShort: String(amountCredits - avaliableCreditsUser) }) : ''}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                {t('athlete.payment.creditsAvailable', { credits: avaliableCreditsUser })}
                            </p>
                        </>
                    )}

                    <Link to={AppRoute.Credit} data-testid="market-credits-link">
                        <span>{t('athlete.payment.getMore')}</span>
                    </Link>
                </div>
                <button
                    type="button"
                    className="btn btn--purple"
                    onClick={handleAthleteSupport}
                    disabled={isAvaliableCreditShort || !isTermsAccepted || isFetching}
                >
                    {t('athlete.payment.supportNow')}
                </button>
                <p className="modal--secondary__body__payment-modal__info">{t('athlete.payment.info')}</p>
                <div className="modal--secondary__body__payment-modal__terms">
                    <label className="control control--checkbox">
                        <input
                            name="agree"
                            type="checkbox"
                            checked={isTermsAccepted}
                            onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                        />
                        <div className="indicator" />
                        <p>{t('athlete.payment.term')}&nbsp;
                            <a href="#a" onClick={() => setShowTermsAndConditions(true)}>
                                {t('athlete.payment.termLink')}
                            </a>
                        </p>
                    </label>
                    {showTermsAndConditions && (
                        <TermsAndConditionsScreen
                            close={() => setShowTermsAndConditions(false)}
                            tab={TabType.TERMS_CONDITIONS}
                            inRegister
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default withAuthenticationContext(withUserContext(withTranslationContext(withWalletContext(AthleteSupportModal))));
