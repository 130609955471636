/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, {
    FunctionComponent, ReactNode, useEffect, useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import {
    ListParams,
    NetworkParams,
    SEARCH_PARAM,
} from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { withUserContext, UserContext } from '../controllers/user/UserContext';
import {
    User, UserFilter, UserRoles,
} from '../../types/user';
import defaultAvatar from '../../assets/images/avatar_default.svg';
import loader from '../../assets/images/loader.svg';
import { AppRoute } from '../../constants/routes';
import Filter from '../elements/Filter';
import { SportTypes } from '../../types/sport';
import { FilterOptions } from '../../types/filter';
import { CountryType } from '../../types/country';

const limit = 20;

interface OwnProps extends RouteComponentProps<NetworkParams>, AuthenticationContext, UserContext, TranslationContext { }

type Props = OwnProps;

const SportrackNetworkComponent: FunctionComponent<OwnProps> = (props: Props) => {
    const {
        user: authenticatedUser, t, searchUsers, getUsers, history, match: { params: { [SEARCH_PARAM]: searchParam } },
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(searchParam !== undefined && searchParam.length > 2);

    const [page, setPage] = useState(0);
    const [total, setTotal] = useState<number | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    
    const [userTypes, setUserTypes] = useState<UserFilter[]>([UserFilter.ALL]);
    const [sports, setSports] = useState<SportTypes[]>([]);
    const [country, setCountry] = useState<CountryType | null | undefined>(null);
    const [regions, setRegions] = useState<string[] | undefined>([]);

    const { ref: bottomElementRef, inView, entry } = useInView({ threshold: [1.0] });

    useEffect(() => {
        if (inView && entry && entry.isIntersecting && !isLoading) fetchUsers();
        // eslint-disable-next-line
    }, [inView, entry, isLoading]);

    useEffect(() => {
        if (total === null) fetchUsers();
        // eslint-disable-next-line
    }, [total]);
    
    useEffect(() => {
        setIsSearch(searchParam !== undefined);
        setPage(0);
        setTotal(null);
    }, [searchParam]);

    const onAthleteClick = (id: number): void => {
        history.push(`${AppRoute.Athlete}/${id}`);
    };

    const onSponsorClick = (id: number): void => {
        history.push(`${AppRoute.Sponsor}/${id}`);
    };

    const fetchUsers = async () => {
        let countryParams;
        let regionsParams;
        let sportsParams;
        let typeParams;
        
        if (total !== null && total <= users.length) return;
        setIsLoading(true);

        const params: ListParams = { _limit: limit, _page: page };
        
        if (!userTypes.includes(UserFilter.ALL)) {
            typeParams = { type: userTypes };
        }
        
        if (sports.length > 0) {
            sportsParams = { sport: sports };
        }
        
        if (country !== undefined && country !== null) {
            countryParams = { country: country.code };
        }
        
        if (regions !== undefined && regions.length > 0) {
            regionsParams = { region: regions };
        }

        const { data, total: totalUsers } = isSearch ? await searchUsers({
            ...params, [SEARCH_PARAM]: searchParam, ...typeParams, ...sportsParams, ...countryParams, ...regionsParams,
        }) : await getUsers({
            ...params, ...typeParams, ...sportsParams, ...countryParams, ...regionsParams,
        });

        setUsers((prevUsers) => (page === 0 ? [...data] : [...prevUsers, ...data]));
        setTotal(totalUsers);
        setPage((prevPage) => prevPage + 1);

        setIsLoading(false);
    };

    const setFilter = (filter: FilterOptions) => {
        setPage(0);
        setTotal(null);
        setUserTypes(filter.userTypes);
        setSports(filter.sports);
        setCountry(filter.country);
        setRegions(filter.regions);
    };

    const renderBody = (): ReactNode => {
        return (
            <>
                <div className="rede-sportrack-screen__body">
                    <div className="rede-sportrack-screen__filter">
                        <Filter
                            onFilterSubmit={setFilter}
                        />
                    </div>
                    <div className="rede-sportrack-screen__users">
                        {!isLoading && users.length <= 0 && (
                            <div aria-label="no-results" className="rede-sportrack-screen__users--empty">
                                {renderNoResultsMessage()}
                            </div>
                        )}
                        {users.length > 0 && (
                            <>
                                <span className="rede-sportrack-screen__users__counter">
                                    {t(`network.${userTypes}.counter`, { total: total ?? 0 })}
                                </span>
                                <div className="rede-sportrack-screen__users__grid">
                                    {users.map(renderCard)}
                                </div>
                            </>
                        )}
                        {isLoading && (
                            <div className="rede-sportrack-screen__users--loading">
                                <img src={loader} alt="loader" />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const renderCard = (user: User, userIndex: number): ReactNode => {
        const {
            hiddenProfile, id, avatar, name, lastName, club, role,
        } = user;

        const key = `${userIndex}-${id}`;

        if (hiddenProfile && authenticatedUser?.role !== UserRoles.Admin) return <div key={key} />;

        const isAthlete = role === UserRoles.Athlete;

        const callback = isAthlete ? onAthleteClick : onSponsorClick;

        const className = isAthlete ? 'athlete' : 'sponsor';

        const description = isAthlete
            ? t(`${club ? `sports.${club.clubCompositeKey.sport}` : 'sports.NA'}`)
            : t('sponsors.sponsor');

        return (
            <div
                aria-label={className}
                key={key}
                className="rede-sportrack-screen__users__grid__item"
                onClick={() => callback(id)}
            >
                <hr className={className} />
                <div className="rede-sportrack-screen__users__grid__item__container">
                    <div className="rede-sportrack-screen__users__grid__item__container__info">
                        <img src={avatar?.main || defaultAvatar} alt="" />
                        <span className="rede-sportrack-screen__users__grid__item__container__info__name">{`${name} ${isAthlete ? lastName : ''}`}</span>
                        <span className={`rede-sportrack-screen__users__grid__item__container__info__sport--${className}`}>
                            {description}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderNoResultsMessage = () => {
        if (searchParam && searchParam.length < 3) return t('network.noResults.search.moreCharacters');

        if (!sports.length && (searchParam && searchParam.length > 2)) {
            return (
                <>
                    <span>{t('network.noResults.search.phrase', { search: decodeURIComponent(searchParam) })}</span>
                    <div>
                        <span>{t('network.noResults.search.tips.parag1')}</span>
                        <li>{t('network.noResults.search.tips.bullet1')}</li>
                        <li>{t('network.noResults.search.tips.bullet2')}</li>
                    </div>
                </>
            );
        }

        if (!!sports.length && (searchParam && searchParam.length > 2)) {
            return (
                <>
                    <span>{t('network.noResults.search.phrase2', { search: decodeURIComponent(searchParam), sport: sports.map((sport) => t(`sports.${sport}`)).join(', ') })}</span>
                    <div>
                        <span>{t('network.noResults.search.tips.parag1')}</span>
                        <li>{t('network.noResults.search.tips.bullet1')}</li>
                        <li>{t('network.noResults.search.tips.bullet2')}</li>
                        <li>{t('network.noResults.search.tips.bullet3')}</li>
                    </div>
                </>
            );
        }

        if (!!sports.length && !searchParam) {
            return (
                <>
                    <span>{t('network.noResults.search.phrase3', { sport: sports.map((sport) => t(`sports.${sport}`)).join(', ') })}
                    </span>
                    <div>
                        <span>{t('network.noResults.search.tips.parag1')}</span>
                        <li>{t('network.noResults.search.tips.bullet3')}</li>
                    </div>
                </>
            );
        }

        if (users.length <= 0) {
            return t('network.noResults.empty');
        }
    };

    return (
        <div className="rede-sportrack-screen" data-testid="rede-sportrack-screen">
            {renderBody()}
            <div ref={bottomElementRef} className="rede-sportrack-screen__bottom-ref" />
        </div>
    );
};

const SportrackNetwork = withTranslationContext(withUserContext(withAuthenticationContext(withRouter(SportrackNetworkComponent))));

export { SportrackNetwork };
