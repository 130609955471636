/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import iconForAthletes from '../../assets/images/icon_for_athletes.svg';
import iconForManagers from '../../assets/images/icon_for_managers.svg';
import iconForSponsors from '../../assets/images/icon_for_sponsors.svg';
import logoArrow from '../../assets/images/logo_arrow.svg';
import plansArrows from '../../assets/images/plans_arrows.png';
import plans1Can from '../../assets/images/plans_1_can.png';
import plans2Can from '../../assets/images/plans_2_can.png';
import plans3Can from '../../assets/images/plans_3_can.png';
import sponsorsBg from '../../assets/images/sponsors_bg.png';
import institutionalSupport from '../../assets/images/institutional_support.png';
import footerBg from '../../assets/images/footer_bg.png';
import logoFooter from '../../assets/images/logo_center.svg';
import logoVoid from '../../assets/images/logo_void.svg';
import footerBgMobile from '../../assets/images/footer_bg_mobile.png';
import chatIcon from '../../assets/images/chat.png';
import marketLogoText from '../../assets/images/market_logo.png';
import { displayError } from '../../utils/notifications';
import { MatchParams, SHOW_ATHLETE_PLANS_SECTION, SHOW_SPONSORS_SECTION } from '../../constants/misc';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { TabType } from '../../utils/misc';
import IconCheckCircle from '../assets/IconCheckCircle';
import { UserTypes } from '../../types/user';
import { AppRoute } from '../../constants/routes';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteComponentProps<MatchParams>, AuthenticationContext}
 */
interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext { }

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * @typedef {Object} OwnState
 * @property {boolean} showTermsAndConditions
 * @property {string} tab
 */
interface OwnState {
    showTermsAndConditions: boolean;
    tab: string;
}

/**
 * @typedef {Object} State
 */
type State = OwnState;

const initialState: State = {
    showTermsAndConditions: false,
    tab: '',
};

/**
 * shows the initial screen
 * @extends {Component<Props, State>}
 */
class AboutScreen extends Component<Props, State> {
    state = initialState;

    private readonly plansPlan = 'about.plans.plan';

    private readonly plansUnit = 'about.plans.unit';

    private readonly plansExclude = 'about.plans.exclude';

    private readonly plansSubscribe = 'about.plans.subscribe';

    private readonly sponsorsUnit = 'about.sponsors.unit';

    componentDidMount(): void {
        const { location } = this.props;
        if (location.hash) {
            const el = document.getElementById(location.hash.split('#')[1]);
            if (el) el.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    /**
     * handles terms and conditions click
     * @param e
     */
    onTermsAndConditionsClick = (tab: string) => {
        this.setState({
            showTermsAndConditions: true,
            tab,
        });
    };

    /**
     * handles not implemented link click
     * @param {React.MouseEvent} e
     */
    onInvalidClick = (e: React.MouseEvent) => {
        const { t } = this.props;
        e.preventDefault();
        displayError({ message: t('general.unavailable') });
    };

    openRegistrationScreen = (userType: UserTypes) => {
        const { openRegistration } = this.props;

        openRegistration(userType);
    }

    render() {
        const { showTermsAndConditions, tab } = this.state;

        const { t } = this.props;

        return (
            <div className="about-screen" data-testid="about-screen">
                <section className="about-us">
                    <div>
                        <h2>{t('about.title')}</h2>
                        <p>
                            {t('about.description.first')}
                            <strong>{t('about.description.second')}</strong>.<br />
                            {t('about.description.third')}&nbsp;
                            <strong>{t('about.description.fourth')}</strong>.<br />
                            {t('about.description.fifth')}
                        </p>
                    </div>
                </section>
                <section id="for-who">
                    <ul className="forwho">
                        <li className="forwho__item forwho__item--athletes">
                            <div className="top">
                                <div>
                                    <img src={iconForAthletes} alt="" />
                                    <p>{t('about.athletes.title')}</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <h2>{t('about.athletes.header')}</h2>
                                <ul className="details-list">
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.athletes.dedicatedManagement')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.athletes.sportsResultsClinicalRecords')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.athletes.postCareerMentoring')}
                                    </li>
                                </ul>
                                <Link to={AppRoute.Credit}>
                                    <button type="button" className="btn btn--primary-light">
                                        <img src={marketLogoText} alt="" />
                                    </button>
                                </Link>
                            </div>
                        </li>
                        <li className="forwho__item forwho__item--managers">
                            <div className="top">
                                <div>
                                    <img src={iconForManagers} alt="" />
                                    <p>{t('about.managers.title')}</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <h2>{t('about.managers.header')}</h2>
                                <ul className="details-list">
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.managers.centralizedManagementPlatform')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.managers.consultancyBooking')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.managers.internationalCareers')}
                                    </li>
                                </ul>
                                <button
                                    type="button"
                                    className="btn btn--primary-inverse"
                                    onClick={() => this.openRegistrationScreen(UserTypes.Manager)}
                                >
                                    {t('about.createYourProfile')}
                                </button>
                            </div>
                        </li>
                        <li className="forwho__item forwho__item--sponsors">
                            <div className="top">
                                <div>
                                    <img src={iconForSponsors} alt="" />
                                    <p>{t('about.sponsor.title')}</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <h2>{t('about.sponsor.header')}</h2>
                                <ul className="details-list">
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.sponsor.brandAwareness')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.sponsor.onboardingTalentEmployeeBranding')}
                                    </li>
                                    <li>
                                        <IconCheckCircle />
                                        {t('about.sponsor.streamingSportsEvents')}
                                    </li>
                                </ul>
                                <Link to={AppRoute.Credit}>
                                    <button
                                        type="button"
                                        className="btn btn--primary-inverse"
                                    >
                                        <img src={marketLogoText} alt="" />
                                    </button>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </section>
                <section className="sport-management">
                    <div className="bubble-box">
                        <img src={logoArrow} alt="" />
                        <div>
                            <div className="top">
                                <h2>{t('about.management.title')}</h2>
                                <p className="subtitle">{t('about.management.subtitle')}</p>
                                <p>
                                    {t('about.management.text1')}
                                    <br />
                                    <span>{t('about.management.text2')}</span>
                                </p>
                                <p>{t('about.management.text3')}</p>
                                <p>
                                    {t('about.management.text4')}
                                    <br />
                                    <span>{t('about.management.text5')}</span>
                                </p>
                                <p>{t('about.management.text6')}</p>
                                <p>{t('about.management.text7')}</p>
                                <p>{t('about.management.text8')}</p>
                                <p>
                                    {t('about.management.text9')}
                                    <br />
                                    <span>{t('about.management.text10')}</span>
                                    <br />
                                    <span>{t('about.management.text11')}</span>
                                    <br />
                                    <span>{t('about.management.text12')}</span>
                                    <br />
                                    <span>{t('about.management.text13')}</span>
                                    <br />
                                    <span>{t('about.management.text14')}</span>
                                </p>
                            </div>
                            <div className="bottom">
                                <button type="button" className="btn" onClick={this.onInvalidClick}>
                                    <span>{t('about.solution')}</span>
                                </button>
                                <p>{t('about.management.professional')}</p>
                                <p>
                                    <span>{t('about.subscribe')}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="health-care">
                    <div className="bubble-box reverse">
                        <img src={logoArrow} alt="" />
                        <div>
                            <div className="top">
                                <h2>{t('about.healthcare.title')}</h2>
                                <p className="subtitle">{t('about.healthcare.subtitle')}</p>
                                <p>{t('about.healthcare.text1')}</p>
                                <p>{t('about.healthcare.text2')}</p>
                                <p>{t('about.healthcare.text3')}</p>
                                <p>{t('about.healthcare.text4')}</p>
                                <p>{t('about.healthcare.text5')}</p>
                                <p>{t('about.healthcare.text6')}</p>
                                <p>{t('about.healthcare.text7')}</p>
                                <p>
                                    {t('about.healthcare.text8')}
                                    <br />
                                    <span>{t('about.healthcare.text9')}</span>
                                    <br />
                                    <span>{t('about.healthcare.text10')}</span>
                                    <br />
                                    <span>{t('about.healthcare.text11')}</span>
                                    <br />
                                    <span>{t('about.healthcare.text12')}</span>
                                </p>
                            </div>
                            <div className="bottom">
                                <button type="button" className="btn" onClick={this.onInvalidClick}>
                                    {t('about.solution')}
                                </button>
                                <p>{t('about.healthcare.professional')}</p>
                                <p>
                                    <span>{t('about.subscribe')}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {SHOW_ATHLETE_PLANS_SECTION && (
                    <section className="plans" id="plans">
                        <div>
                            <p>{t('about.plans.description')}</p>
                            <h2>{t('about.plans.title')}</h2>
                            <h4>
                                {t('about.plans.header1')}
                                <span>{t('about.plans.header2')}</span>
                                {t('about.plans.header3')}
                            </h4>
                        </div>
                        <ul>
                            <li className="plan1">
                                <img src={plansArrows} alt="" className="arrows" />
                                <img src={plans1Can} alt="" className="can" />
                                <div className="plan">
                                    <h3>
                                        {t('about.plans.plan1.name')}
                                        <br />
                                        <span>{t(this.plansPlan)}</span>
                                    </h3>
                                    <div className="description">
                                        <p>{t('about.plans.plan1.description1')}</p>
                                        <p>{t('about.plans.plan1.description2')}</p>
                                        <p>{t('about.plans.plan1.description3')}</p>
                                        <p>{t('about.plans.plan1.description4')}</p>
                                    </div>
                                    <div className="bottom">
                                        <p>{t('about.plans.plan1.ideal')}</p>
                                        <div>
                                            <div>
                                                <div>
                                                    <p>
                                                        {t('about.plans.plan1.price')}
                                                        <span>{t(this.plansUnit)}</span>
                                                    </p>
                                                    <p className="small">{t(this.plansExclude)}</p>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn--inverse"
                                                onClick={this.onInvalidClick}
                                            >
                                                {t(this.plansSubscribe)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="plan2">
                                <img src={plansArrows} alt="" className="arrows" />
                                <img src={plans2Can} alt="" className="can" />
                                <div className="plan">
                                    <h3>
                                        {t('about.plans.plan2.name')}
                                        <br />
                                        <span>{t(this.plansPlan)}</span>
                                    </h3>
                                    <div className="description">
                                        <p>{t('about.plans.plan2.description1')}</p>
                                        <p className="and">{t('about.plans.plan2.description2')}</p>
                                        <p>{t('about.plans.plan2.description3')}</p>
                                        <p>{t('about.plans.plan2.description4')}</p>
                                        <p>{t('about.plans.plan2.description5')}</p>
                                    </div>
                                    <div className="bottom">
                                        <p>{t('about.plans.plan2.ideal')}</p>
                                        <div>
                                            <div>
                                                <div>
                                                    <p>
                                                        {t('about.plans.plan2.price')}
                                                        <span>{t(this.plansUnit)}</span>
                                                    </p>
                                                    <p className="small">{t(this.plansExclude)}</p>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn--inverse"
                                                onClick={this.onInvalidClick}
                                            >
                                                {t(this.plansSubscribe)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="plan3">
                                <img src={plansArrows} alt="" className="arrows" />
                                <img src={plans3Can} alt="" className="can" />
                                <div className="plan">
                                    <h3>
                                        {t('about.plans.plan3.name')}
                                        <br />
                                        <span>{t(this.plansPlan)}</span>
                                    </h3>
                                    <div className="description">
                                        <p>{t('about.plans.plan3.description1')}</p>
                                        <p className="and">{t('about.plans.plan3.description2')}</p>
                                        <p>{t('about.plans.plan3.description3')}</p>
                                        <p>{t('about.plans.plan3.description4')}</p>
                                        <p>{t('about.plans.plan3.description5')}</p>
                                    </div>
                                    <div className="bottom">
                                        <p>{t('about.plans.plan3.ideal')}</p>
                                        <div>
                                            <div>
                                                <div>
                                                    <p>
                                                        96€<span>{t(this.plansUnit)}</span>
                                                    </p>
                                                    <p className="small">{t(this.plansExclude)}</p>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn--inverse"
                                                onClick={this.onInvalidClick}
                                            >
                                                {t(this.plansSubscribe)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                )}
                {SHOW_SPONSORS_SECTION && (
                    <section className="sponsors">
                        <div>
                            <div>
                                <h2>{t('about.sponsors.description')}</h2>
                                <h4>{t('about.sponsors.title')}</h4>
                                <h5>
                                    {t('about.sponsors.header1')}
                                    <span>{t('about.sponsors.header2')}</span>
                                </h5>
                                <p>
                                    <span>/</span> {t('about.sponsors.point1')}
                                </p>
                                <p>
                                    <span>/</span> {t('about.sponsors.point2')}
                                </p>
                                <p>
                                    <span>/</span> {t('about.sponsors.point3')}
                                </p>
                            </div>
                            <img src={sponsorsBg} alt="" />
                        </div>
                        <ul>
                            <li className="plan plan--standard">
                                <h3>
                                    {t('about.sponsors.plan1.name')}
                                    <br />
                                    <span>{t(this.plansPlan)}</span>
                                </h3>
                                <div className="description">
                                    <p>{t('about.sponsors.plan1.description1')}</p>
                                    <p>{t('about.sponsors.plan1.description2')}</p>
                                    <p>{t('about.sponsors.plan1.description3')}</p>
                                    <p>{t('about.sponsors.plan1.description4')}</p>
                                </div>
                                <div className="bottom">
                                    <div>
                                        <div>
                                            <div>
                                                <p>
                                                    {t('about.sponsors.plan1.price')}
                                                    <span>{t(this.sponsorsUnit)}</span>
                                                </p>
                                                <p className="small">{t(this.plansExclude)}</p>
                                            </div>
                                        </div>
                                        <button type="button" className="btn" onClick={this.onInvalidClick}>
                                            {t(this.plansSubscribe)}
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li className="plan plan--performance">
                                <h3>
                                    {t('about.sponsors.plan2.name')}
                                    <br />
                                    <span>{t(this.plansPlan)}</span>
                                </h3>
                                <div className="description">
                                    <p>{t('about.sponsors.plan2.description1')}</p>
                                    <p>{t('about.sponsors.plan2.description2')}</p>
                                    <p>{t('about.sponsors.plan2.description3')}</p>
                                    <p>{t('about.sponsors.plan2.description4')}</p>
                                </div>
                                <div className="bottom">
                                    <div>
                                        <div>
                                            <div>
                                                <p>
                                                    {t('about.sponsors.plan2.price')}
                                                    <span>{t(this.sponsorsUnit)}</span>
                                                </p>
                                                <p className="small">{t(this.plansExclude)}</p>
                                            </div>
                                        </div>
                                        <button type="button" className="btn" onClick={this.onInvalidClick}>
                                            {t(this.plansSubscribe)}
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li className="plan plan--universal">
                                <h3>
                                    {t('about.sponsors.plan3.name')}
                                    <br />
                                    <span>{t(this.plansPlan)}</span>
                                </h3>
                                <div className="description">
                                    <p>{t('about.sponsors.plan3.description1')}</p>
                                    <p>{t('about.sponsors.plan3.description2')}</p>
                                    <p>{t('about.sponsors.plan3.description3')}</p>
                                    <p>{t('about.sponsors.plan3.description4')}</p>
                                </div>
                                <div className="bottom">
                                    <div>
                                        <div>
                                            <div>
                                                <p>
                                                    {t('about.sponsors.plan3.price')}
                                                    <span>{t(this.sponsorsUnit)}</span>
                                                </p>
                                                <p className="small">{t(this.plansExclude)}</p>
                                            </div>
                                        </div>
                                        <button type="button" className="btn" onClick={this.onInvalidClick}>
                                            {t(this.plansSubscribe)}
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                )}
                <section className="institutional-support">
                    <img src={institutionalSupport} alt="" />
                    <div>
                        <h3>{t('about.institutional.header')}</h3>
                        <h2>{t('about.institutional.title')}</h2>
                        <p>{t('about.institutional.description')}</p>
                        <button type="button" className="btn" onClick={this.onInvalidClick}>
                            {t('about.institutional.contact')}
                        </button>
                        <p className="small">{t('about.institutional.service')}</p>
                    </div>
                </section>
                <section className="footer">
                    <div className="footer__top-container">
                        <div className="details">
                            <img src={logoFooter} alt="footer logo" />
                            <div className="contacts">
                                <h2 className="title title--contacts">{t('about.addresses.contacts.title')}</h2>
                                <p><strong>{t('privacyModal.terms.parag6_1.part1_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part1_2')}</p>
                                <p><strong>{t('privacyModal.terms.parag6_1.part2_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part2_2')}</p>
                                <p><strong>{t('privacyModal.terms.parag6_1.part3_1')}</strong>&#32;{t('privacyModal.terms.parag6_1.part3_2')}</p>
                                <p><strong>{t('privacyModal.terms.parag6_1.part4_1')}</strong>&#32;<a href={`mailto:${t('privacyModal.terms.mail')}`}>{t('privacyModal.terms.mail')}</a></p>
                                <a href="https://discord.com/invite/mp884fd3zS">
                                    <button type="button" className="btn btn--chat">
                                        <img src={chatIcon} alt="chat" />
                                        {t('about.addresses.contacts.chat')}
                                    </button>
                                </a>
                            </div>
                            
                        </div>
                        <img src={footerBg} alt="footer background" />
                    </div>
                    <div className="links">
                        <div>
                            {t('about.footer.copyright')}
                            <a href="#a" onClick={() => this.onTermsAndConditionsClick(TabType.ABOUT_US)}>
                                {t('about.footer.about')}
                            </a>
                            <a href="#a" onClick={() => this.onTermsAndConditionsClick(TabType.TERMS_CONDITIONS)}>
                                {t('about.footer.terms')}
                            </a>
                            <a href="#a" onClick={() => this.onTermsAndConditionsClick(TabType.PRIVACY_POLICY)}>
                                {t('about.footer.privacy')}
                            </a>
                            <a href="#a" onClick={() => this.onTermsAndConditionsClick(TabType.COOKIES_POLICY)}>
                                {t('about.footer.cookies')}
                            </a>
                            <a href="#a" onClick={() => this.onTermsAndConditionsClick(TabType.GENDER_EQUALITY_PLAN)}>
                                {t('about.footer.gep')}
                            </a>
                        </div>
                        <div className="credits">
                            {t('about.footer.developed')}

                            <a href="https://voidsoftware.com" target="_blank" rel="noopener noreferrer">
                                <img src={logoVoid} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="bottom">
                        <img src={footerBgMobile} alt="" />
                        <div>
                            {t('about.footer.developed')}
                            <a href="https://voidsoftware.com" target="_blank" rel="noopener noreferrer">
                                <img src={logoVoid} alt="" />
                            </a>
                        </div>
                    </div>
                </section>

                {showTermsAndConditions && (
                    <TermsAndConditionsScreen
                        close={() => this.setState({ showTermsAndConditions: false })}
                        tab={tab}
                        inRegister={false}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(withAuthenticationContext(withTranslationContext(AboutScreen)));
