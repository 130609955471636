/*
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';

import { WalletContextProvider } from './WalletContext';
import { TranslationContext, withTranslationContext } from '../translation/TranslationContext';
import { CreditRequest, WalletTransactions } from '../../../types/credit';
import { walletTransactionListURL, walletTransactionURL } from '../../../services/wallet';
import { Pack, PaymentData } from '../../../constants/market';
import { paymentsURL, athleteSupportURL, creditCardPaymentURL } from '../../../services/payment';
import { KeyedObject } from '../../../constants/misc';
import { AppRoute } from '../../../constants/routes';
import { WEB_URL } from '../../../settings';
import { RedirectUrl } from '../../../types/wallet';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

export class WalletController extends Component<Props> {
    submitWalletTransfer = async (payload: CreditRequest, onSuccess: Function, onFailure: Function): Promise<void> => {
        try {
            await axios.post(walletTransactionURL(), payload);
            onSuccess();
        } catch (error) {
            const err = error as AxiosError;
            onFailure(err?.response?.data);
        }
    }

    getWalletTransactions = async (params: Record<string, number | string>): Promise<WalletTransactions[] | null> => {
        const { handleAPIErrors } = this.props;
        try {
            const res = await axios.get(walletTransactionListURL(params));
            return res.data;
        } catch (error) {
            const err = error as AxiosError;
            handleAPIErrors(err.response?.data, true);
            return null;
        }
    };

    submitPayment = async (paymentData: PaymentData, onSuccess: () => void, onFailure: (error: KeyedObject) => void): Promise<void> => {
        const { handleAPIErrors } = this.props;

        try {
            await axios.post(paymentsURL(), paymentData);
            onSuccess();
        } catch (e) {
            const err = e as AxiosError;
            handleAPIErrors(err.response?.data, true);
            onFailure(err.response?.data || {});
        }
    };

    submitCreditCardPaymentRequest = async (product: Pack, onRedirectSuccess: () => void): Promise<void> => {
        const { language, handleAPIErrors } = this.props;

        const paymentRequestObject = {
            payment: {
                successUrl: `${WEB_URL}${AppRoute.PaymentSuccess}`,
                failUrl: `${WEB_URL}${AppRoute.PaymentFail}`,
                backUrl: `${WEB_URL}${AppRoute.Credit}`,
                lang: language,
            },
            customer: {
                notify: true,
            },
            creditsAmount: product.credits,
        };

        try {
            const { data } = await axios.post<RedirectUrl>(creditCardPaymentURL(), paymentRequestObject);
            const { redirectUrl } = data;
            window.open(redirectUrl);
            onRedirectSuccess();
        } catch (e) {
            const err = e as AxiosError;
            handleAPIErrors(err.response?.data, true);
        }
    }

    submitAthleteSupport = async (athleteId: string, credits: number, onSuccess: () => void, onFailure: (error: KeyedObject) => void): Promise<void> => {
        try {
            await axios.post(athleteSupportURL(athleteId, credits));
            onSuccess();
        } catch (e) {
            const err = e as AxiosError;
            onFailure(err.response?.data?.errors?.[0]?.message);
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <WalletContextProvider
                value={{
                    submitWalletTransfer: this.submitWalletTransfer,
                    getWalletTransactions: this.getWalletTransactions,
                    submitPayment: this.submitPayment,
                    submitCreditCardPaymentRequest: this.submitCreditCardPaymentRequest,
                    submitAthleteSupport: this.submitAthleteSupport,
                }}
            >
                {children}
            </WalletContextProvider>
        );
    }
}

export const ConnectedWalletController = connect()(withTranslationContext(WalletController));
