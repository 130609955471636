/*
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import {
    BLISSCARE_PRODUCTION_ID,
    BLISSCARE_RELEASE_ID,
    BLISSCARE_TEST_ID,
    Environment,
    KeyedObject,
} from '../constants/misc';
import { Achievement } from '../constants/achievements';
import { ENVIRONMENT } from '../settings';
import { userURL } from '../services/users';
import { UserRoles } from '../types/user';
import { AppRoute } from '../constants/routes';

export const generateId = (): string => {
    return Math.random().toString(36).slice(2);
};

export const objectToParams = (obj: KeyedObject, useQ = true): string => {
    let params = '';

    Object.keys(obj).forEach((key) => {
        const val = `${obj[key]}`;
        if (val && val.length > 0) {
            if (params.length > 0) {
                params = `${params}&${key}=${val}`;
            } else {
                params = `${key}=${obj[key]}`;
            }
        }
    });

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const arrayToParams = (paramName: string, arrayValues: any[], useQ = true): string => {
    let params = '';

    if (Array.isArray(arrayValues)) {
        arrayValues.forEach((value) => {
            const includeAnd = params.length > 0 ? '&' : '';
            params += `${includeAnd}${paramName}=${value}`;
        });
    }

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const throttle = (func: any, time: number): Function => {
    let lastTime = 0;
    return (...args: any[]): any => {
        const now = (new Date()).getTime();

        if (now - lastTime >= time) {
            func(...args);
            lastTime = now;
        }
    };
};

export const sortAchievementsByDate = (achievements: Array<Achievement>) => {
    return [...achievements.sort((a: Achievement, b: Achievement) => moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD'), 'days'))];
};

export const verifyShowBlisscareAthletes = (sponsorId: number): boolean => {
    const blisscareTestOrDev = sponsorId === BLISSCARE_TEST_ID && (ENVIRONMENT === Environment.DEV || ENVIRONMENT === Environment.TEST);
    const blisscareRelease = sponsorId === BLISSCARE_RELEASE_ID && ENVIRONMENT === Environment.RELEASE;
    const blisscareProduction = sponsorId === BLISSCARE_PRODUCTION_ID && ENVIRONMENT === Environment.PRODUCTION;
    return blisscareTestOrDev || blisscareRelease || blisscareProduction;
};

export const blisscareAthletesRequestsEndpoints = () => {
    if (ENVIRONMENT === Environment.RELEASE) {
        return [userURL(249962), userURL(249963), userURL(249964)];
    }

    if (ENVIRONMENT === Environment.PRODUCTION) {
        return [userURL(149974), userURL(149968), userURL(149952)];
    }

    return [userURL(153953), userURL(153955), userURL(153954)];
};

export const getBlisscareId = (): number => {
    if (ENVIRONMENT === Environment.RELEASE) return BLISSCARE_RELEASE_ID;

    if (ENVIRONMENT === Environment.PRODUCTION) return BLISSCARE_PRODUCTION_ID;

    return BLISSCARE_TEST_ID;
};

/**
 * enum from TabTypes on privacy policy modal
 */
export enum TabType {
    ABOUT_US = 'ABOUT_US',
    TERMS_CONDITIONS = 'TERMS_CONDITIONS',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    COOKIES_POLICY = 'COOKIES_POLICY',
    GENDER_EQUALITY_PLAN = 'GENDER_EQUALITY_PLAN',
}

/**
 * get url profile by user role
 */
export const getProfileUrl = (role: string, userId: number) => {
    switch (role) {
        case UserRoles.Athlete:
            return `${AppRoute.Athlete}/${userId}`;
        case UserRoles.Manager:
            return `${AppRoute.Manager}/${userId}`;
        case UserRoles.Sponsor:
            return `${AppRoute.Sponsor}/${userId}`;
        case UserRoles.Fan:
            return `${AppRoute.Fan}/${userId}`;
        default:
            return `${AppRoute.Athlete}/${userId}`;
    }
};

export const buildRoute = <T extends string> (route: T, params: Record<string, string | number>): string => {
    let finalRoute: string = route;

    Object.keys(params).forEach((key) => {
        finalRoute = finalRoute.replace(`:${key}`, `${params[key]}`);
    });
    
    return finalRoute;
};
